import { RouteInfo } from './sidebar.metadata';

export const QUEUEGROUTES: RouteInfo[] = [
  {
    path: '/rez/dashboard', title: 'Dashboard', icon: 'foh-icon foh-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    alias: "queue", path: '/queue/operations', title: 'Queue', icon: 'foh-reservation foh-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    alias: "customers", path: '/customers/list', title: 'Profiles', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
];

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { Globals } from '../../globals';
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/do";
//import { toPromise } from "rxjs/operators";

export enum Environment {
  Prod = "prod",
  Staging = "staging",
  Test = "test",
  Dev = "dev",
  Local = "local"
}

interface Configuration {
  configUrl: string;
  stage: Environment;
}

@Injectable({
  providedIn: "root"
})
export class EnvConfigurationService {
  private readonly configUrl = "assets/config/";
  private configuration$: Observable<Configuration>;

  constructor(private http: HttpClient,
    private authService: AuthService,
    private globals: Globals) { }
  public load(): Promise<any> {
    var hostName = window.location.hostname;
    var fileName = "env-detector.php";
    if (hostName == "localhost") {
      fileName = "local.json";
    }
    const response = this.http
      .get<any>(`${this.configUrl}` + fileName)
      .toPromise()
      .then((res: any) => {
        this.globals.setConstants(res);
        this.authService.refreshUser();
        return res;
      });
    return response;
  }
}

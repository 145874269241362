import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@foh/globals';
import { CookieService } from "ngx-cookie-service";
declare let gtag: Function;

@Injectable()
export class AuthService {
  token: string;
  user: any;
  currentSelectedModule: any;

  constructor(private http: HttpClient,
    private globals: Globals,
    private cookieService: CookieService
  ) {
    this.token = null;
    if (this.user == null) {
      gtag("config", this.globals.GOOGLEANALYTICS);
    } else {
      gtag('set', 'userId', this.user.id);
    }
  }

  refreshUser() {
    //Refresh
    this.user = JSON.parse(localStorage.getItem(this.globals.STORAGE_PREFIX + "user"));

    var hasNewTag = localStorage.getItem(this.globals.STORAGE_PREFIX + "v354_compatible");

    //Just for two weeks
    if (hasNewTag == null || hasNewTag != "compatible") {
      this.logout();
    }

  }

  // signupUser(email: string, password: string) {
    
  // }

  signinUser(email: string, password: string) {
    return this.http.post(this.globals.WEBAPI + this.globals.URL_SIGNIN,
      {
        email: email,
        password: password
      });
  }

  logInUser(data: {}) {
    this.token = data['token'];
    //localStorage.setItem("token",this.token  );
    this.cookieService.set(this.globals.STORAGE_PREFIX + "token", this.token, 30, "/", this.globals.DOMAIN, this.globals.SECURECOOKIE, null);
    this.user = data['user'];
    localStorage.setItem(this.globals.STORAGE_PREFIX + "user", JSON.stringify(this.user));
    gtag('set', 'userId', this.user.id);
  }

  updateToken(data: {}) {
    this.token = data['token'];
    //localStorage.setItem("token",this.token  );
    this.cookieService.set(this.globals.STORAGE_PREFIX + "token", this.token, 30, "/", this.globals.DOMAIN, this.globals.SECURECOOKIE, null);
  }

  viewAsMCompany(id) {
    return this.http.post(this.globals.WEBAPI + 'usersmangement/users/viewas',
      {
        id: id
      });
  }

  logout() {
    this.token = null;
    this.user = null;
    localStorage.removeItem(this.globals.STORAGE_PREFIX + "user");
    this.cookieService.delete(this.globals.STORAGE_PREFIX + "token", "/", this.globals.DOMAIN);
    this.removeCachedData();
  }

  removeCachedData() {
    localStorage.clear();
    localStorage.setItem(this.globals.STORAGE_PREFIX + "v354_compatible", "compatible");
    // var keyString = this.globals.STORAGE_PREFIX;
    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i);
    //     localStorage.removeItem(key);
    // }
  }

  getToken() {
    //return this.token;
    //return localStorage.getItem("token");
    return this.cookieService.get(this.globals.STORAGE_PREFIX + "token");
  }

  getUser() {
    return JSON.parse(
      localStorage.getItem(this.globals.STORAGE_PREFIX + "user")
    );
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    var cookieIsThere = this.cookieService.check(this.globals.STORAGE_PREFIX + "token");
    var user = this.getUser();

    if (!cookieIsThere || user == null) {
      return false;
    }
    if (this.getToken() == null) return false;
    return true;
  }

  isSuperAdmin() {
    var state = false;
    for (var i = 0; i < this.user['permissions'].length; i++) {
      if (this.user['permissions'][i].name == this.globals.PERMISSION_SUPERADMIN) {
        state = true;
        break;
      }
    }
    return state;
  }

  havePermission(permissionName) {
    var found = false;
    if (this.isSuperAdmin()) {
      return true;
    }
    for (var i = 0; i < this.user["roles"].length; i++) {
      if (this.user["roles"][i].module != this.currentSelectedModule) {
        continue;
      }
      var permissionOfRole = this.user["roles"][i]["permissions"];
      for (var j = 0; j < permissionOfRole.length; j++) {
        if (permissionOfRole[j]['name'] == permissionName) {
          found = true;
          break;
        }
      }
    }
    return found;
  }

  accessToVenue(venueId) {
    var state = false;
    for (var i = 0; i < this.user['permissions'].length; i++) {
      if (this.user['permissions'][i].name == this.globals.PERMISSION_VENUE + venueId) {
        state = true;
        break;
      }
    }
    return state;
  }

  accessToBranch(branchId) {
    var state = false;
    for (var i = 0; i < this.user['permissions'].length; i++) {
      if (this.user['permissions'][i].name == this.globals.PERMISSION_BRANCH + branchId) {
        state = true;
        break;
      }
    }
    return state;
  }

  accessToFohModule(module) {
    if (!this.isAuthenticated()) {
      return false;
    }
    if (this.isSuperAdmin()) {
      return true;
    }
    for (var i = 0; i < this.user['roles'].length; i++) {
      if (this.user['roles'][i].module == module) {
        return true;
      }
    }
    return false;
  }

  setModule(module) {
    this.currentSelectedModule = module;
  }
}

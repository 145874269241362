import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '@foh/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient, private globals: Globals) { }

  getFullMenu(params: HttpParams): Observable<any> {
    return this.http.get(this.globals.WEBAPI + this.globals.URL_Get_FeedBack_Questions_MetaData, { params: params });
  }

  postAnswers(formData: FormData): Observable<any> {
    return this.http.post(this.globals.WEBAPI + this.globals.URL_SUBMIT_FEEDBACK_ANSWERS, formData);
  }

}

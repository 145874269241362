import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public toastr: ToastrService) { }

  clearAll(){
      this.toastr.clear();
  }

  changesSaved(){
      this.toastr.success('Changes are saved', 'Saved!');
  }

  unknownError(){
      this.toastr.error("An error occuried.", 'Error!');
  }

  success(title,desc){
      this.toastr.success(desc, title);
  }

  newRequest(){
      this.toastr.info('A New Reservation Request Is Added', 'New Request!');
  }

  error(title,desc){
      this.toastr.error(desc,title);
  }

  newCall(message){
      var toast = this.toastr.info(message,'Incoming Call',  { "progressBar": true,closeButton: true,"timeOut": 20000 } );
      return toast;
  }

  taskCompleted(message){
      var toast = this.toastr.info(message,'Task Completed',  { "progressBar": true,closeButton: true,"timeOut": 20000 } );
      return toast;
  }
}

import { Component, OnInit } from "@angular/core";
import { REZROUTES } from "./sidebar-routes-rez.config";
import { PASSROUTES } from "./sidebar-routes-pass.config";
import { ORDERINGROUTES } from "./sidebar-routes-ordering.config";
import { QUEUEGROUTES } from "./sidebar-routes-queue.config";
import { WEDDINGGROUTES } from "./sidebar-routes-wedding.config";
import { RouteInfo } from "./sidebar.metadata";
import { AuthService } from "../auth/auth.service";
import { LookupService } from "../services/lookup.service";
import { Subscription } from "rxjs";
import { ReservationupdaterService } from "@foh/shared/services/reservationupdater.service";
import { Globals } from "@foh/globals";
import { warning } from "@foh/shared/data/sweet-alerts";
import { ReservationsService } from "@foh/reservations/reservations.service";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

declare var $: any;
@Component({
  // moduleId: module.id,
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html"
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  reservationUpdaterListener: Subscription;
  currentVersion: string;
  nextBillingDate: any;
  currentSelectedModule: string;
  shouldDisplaySwitchToRezOption: boolean = false;
  shouldDisplaySwitchToPassOption: boolean = false;
  shouldDisplaySwitchToOrderingOption: boolean = false;
  shouldDisplaySwitchToQueueOption: boolean = false;
  shouldDisplaySwitchToCitadelOption: boolean = false;
  constructor(
    private authService: AuthService,
    private reservationupdaterService: ReservationupdaterService,
    private globals: Globals,
    private reservationsService: ReservationsService,
    private fohmoduleSelectorService: FohmoduleSelectorService,
    private lookupService: LookupService
  ) {
    this.bindReservationUpdaterEvents();
    this.currentVersion = this.globals.CURRENT_VERSION;
  }

  filterRoutes(menuItems) {
    var i = menuItems.length;
    while (i--) {

      const item = menuItems[i];

      // Hide "Switch to Pass" route if user is SuperAdmin
      if (item.path === '/module/switchto/pass' && this.authService.isSuperAdmin()) {
        menuItems.splice(i, 1);
        continue;
      }
      
      if (
        !this.authService.isSuperAdmin() &&
        !this.lookupService.haveValidLicense &&
        (typeof menuItems[i].subscriptionItem === "undefined" ||
          menuItems[i].subscriptionItem !== true)
      ) {
        //If no valid license, skip
        menuItems.splice(i, 1);
        continue;
      }
      if (
        menuItems[i].isSuperAdmin === true &&
        !this.authService.isSuperAdmin()
      ) {
        menuItems.splice(i, 1);
        continue;
      }
      if (typeof menuItems[i].permissions !== "undefined") {
        var haveAccess = this.havePermission(menuItems[i].permissions);
        if (!haveAccess) {
          menuItems.splice(i, 1);
          continue;
        }
      }
      if (menuItems[i].submenu.length > 0) {
        menuItems[i].submenu = this.filterRoutes(menuItems[i].submenu);
      }
    }

    return menuItems;
  }

  havePermission(permissions) {
    var onlyOneFound = false;
    if (this.authService.isSuperAdmin()) {
      return true;
    }
    for (var i = 0; i < permissions.length; i++) {
      if (this.authService.havePermission(permissions[i])) {
        onlyOneFound = true;
        break;
      }
    }
    return onlyOneFound;
  }

  injectReservationsRoutes() {
    if (
      !this.authService.isSuperAdmin() &&
      !this.lookupService.haveValidLicense
    ) {
      //If no valid license, skip
      return;
    }
    var venueLength = this.lookupService.getNumberOfValidVenues();
    var venues = this.lookupService.getVenues();
    var firstVenue = venues[0];
    let numberOfLevels: number;
    let anyVenueHaveMoreThanOneBranch: boolean;
    if (venueLength > 1) {
      //Check If Any Venue have more than one branches
      anyVenueHaveMoreThanOneBranch = this.venueHaveAtleastMoreThanOneBranch(
        venues
      );
      if (anyVenueHaveMoreThanOneBranch) {
        numberOfLevels = 2;
      } else {
        numberOfLevels = 1;
      }
    } else if (
      venueLength == 1 &&
      this.lookupService.getNumberOfBranchesOfVenue(firstVenue) > 1
    ) {
      numberOfLevels = 1;
    } else {
      numberOfLevels = 0;
    }

    switch (numberOfLevels) {
      case 2:
        this.injectTwoLevelsReservationRoutes(venues, "reservations");
        this.injectTwoLevelsReservationRoutes(venues, "guestbook");
        break;
      case 1:
        if (venueLength > 1 && !anyVenueHaveMoreThanOneBranch) {
          this.injectOneLevelReservationRoutesVenueOnly(venues, "reservations");
          this.injectOneLevelReservationRoutesVenueOnly(venues, "guestbook");
        } else {
          this.injectOneLevelReservationRoutes(firstVenue, "reservations");
          this.injectOneLevelReservationRoutes(firstVenue, "guestbook");
        }
        break;
      case 0:
        this.injectZeroLevelReservationRoutes(firstVenue, "reservations");
        this.injectZeroLevelReservationRoutes(firstVenue, "guestbook");
        break;
    }
  }

  venueHaveAtleastMoreThanOneBranch(venues) {
    var haveMoreThanOneBranch = false;
    for (var i = 0; i < venues.length; i++) {
      if (venues[i].branches.length > 1) {
        haveMoreThanOneBranch = true;
        break;
      }
    }
    return haveMoreThanOneBranch;
  }

  injectTwoLevelsReservationRoutes(venues, alias) {
    var array = this.fillVenueInfo(venues, alias);
    var reservationPath = this.getRoutePathByAlias(alias);
    reservationPath.class = "has-sub";
    reservationPath.submenu = array;
  }
  injectOneLevelReservationRoutesVenueOnly(venues, alias) {
    var array = this.fillSingleBranchVenueInfoForVenue(venues, alias);
    var reservationPath = this.getRoutePathByAlias(alias);
    reservationPath.class = "has-sub";
    reservationPath.submenu = array;
  }
  injectOneLevelReservationRoutes(venue, alias) {
    var array = this.fillBranchInfoForVenue(venue, alias);
    var reservationPath = this.getRoutePathByAlias(alias);
    reservationPath.class = "has-sub";
    reservationPath.submenu = array;
  }
  fillVenueInfo(venues, alias) {
    var array = [];
    for (var i = 0; i < venues.length; i++) {
      var arrayOfBrunches = this.fillBranchInfoForVenue(venues[i], alias);
      var jsonObject = {};
      jsonObject["path"] = "";
      jsonObject["title"] = venues[i]["name"];
      jsonObject["icon"] = "";
      jsonObject["class"] = "has-sub";
      jsonObject["badge"] = "";
      jsonObject["badgeClass"] = "";
      jsonObject["isExternalLink"] = false;
      jsonObject["submenu"] = arrayOfBrunches;
      array.push(jsonObject);
    }

    return array;
  }
  fillSingleBranchVenueInfoForVenue(venues, alias) {
    var array = [];
    for (var i = 0; i < venues.length; i++) {
      var jsonObject = {};
      jsonObject["path"] = this._getReservationGuestbookLink(
        alias,
        venues[i].branches[0]["id"]
      );
      jsonObject["title"] = venues[i]["name"];
      jsonObject["icon"] = "";
      jsonObject["class"] = "";
      jsonObject["badge"] = "";
      jsonObject["badgeClass"] = "";
      jsonObject["isExternalLink"] = false;
      jsonObject["submenu"] = [];
      array.push(jsonObject);
    }
    return array;
  }
  fillBranchInfoForVenue(venue, alias) {
    var array = [];
    for (var i = 0; i < venue.branches.length; i++) {
      var jsonObject = {};
      jsonObject["path"] = this._getReservationGuestbookLink(
        alias,
        venue.branches[i]["id"]
      );
      jsonObject["title"] = venue.branches[i]["name"];
      jsonObject["icon"] = "";
      jsonObject["class"] = "";
      jsonObject["badge"] = "";
      jsonObject["badgeClass"] = "";
      jsonObject["isExternalLink"] = false;
      jsonObject["submenu"] = [];
      array.push(jsonObject);
    }
    return array;
  }

  _getReservationGuestbookLink(alias, id) {
    if (alias == "guestbook") {
      return "/" + alias + "/" + id;
    } else if (alias == "reservations") {
      var currentView = this.reservationsService.currentDefaultView();
      if (currentView == "legacy") {
        return "/" + alias + "/legacy/" + id;
      }
      return "/" + alias + "/" + id;
    }
  }
  injectZeroLevelReservationRoutes(venue, alias) {
    var reservationPath = this.getRoutePathByAlias(alias);
    reservationPath["path"] = this._getReservationGuestbookLink(
      alias,
      venue.branches[0].id
    );
  }

  getRoutePathByAlias(alias) {
    var found: any = false;
    for (var i = 0; i < this.menuItems.length; i++) {
      if (typeof this.menuItems[i].alias !== "undefined") {
        if (this.menuItems[i].alias === alias) {
          found = this.menuItems[i];
          break;
        }
      }
      for (var j = 0; j < this.menuItems[i].submenu.length; j++) {
        if (typeof this.menuItems[i].submenu[j].alias !== "undefined") {
          if (this.menuItems[i].submenu[j].alias === alias) {
            found = this.menuItems[i].submenu[j];
            break;
          }
        }
      }
    }
    return found;
  }

  getIndexOfRootPathByAlias(array, alias) {
    var found: any = false;
    for (var i = 0; i < array.length; i++) {
      if (typeof array[i].alias !== "undefined") {
        if (array[i].alias === alias) {
          found = i;
          break;
        }
      }
    }
    return found;
  }

  ngOnInit() {
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();
    $.getScript("./assets/js/app-sidebar.js");
    var currentRoutes = this.selectCurrentRoutes();
    this.menuItems = this.filterRoutes(
      currentRoutes.filter(menuItem => menuItem)
    );
    //this.injectReservationsRoutes();
    if (this.lookupService.lookupLoaded) {
      this.updatePendingRequestsNumber(
        this.lookupService.vars["pendingRequestsCounter"]
      );
      //Check License alert
      this.checkLicenseAlertStatus();
    }
  }

  selectCurrentRoutes() {
    var routes = null;
    switch (this.currentSelectedModule) {
      case "rez":
        routes = REZROUTES;
        break;
      case "pass":
        routes = PASSROUTES;
        break;
      case "ordering":
        routes = ORDERINGROUTES;
        break;
      case "queue":
        routes = QUEUEGROUTES;
        break;
      case "wedding":
        routes = WEDDINGGROUTES;
        break;
    }
    //Append Switch To Module Menu
    this.injectSwitchToModuleRoutes(routes);
    return routes;
  }

  injectSwitchToModuleRoutes(routes) {
    this.refreshSwitchToFlags();
    var switchModulePathIndex = this.getIndexOfRootPathByAlias(routes, "switchmodule");
    if (switchModulePathIndex != false) {
      //Remove Old Switch Module Path
      routes.splice(switchModulePathIndex, 1);
    }
    if (
      !this.shouldDisplaySwitchToRezOption &&
      !this.shouldDisplaySwitchToPassOption &&
      !this.shouldDisplaySwitchToOrderingOption &&
      !this.shouldDisplaySwitchToQueueOption &&
      !this.shouldDisplaySwitchToCitadelOption
    ) {
      return;
    }

    let subRoutes: RouteInfo[] = [];
    if (this.shouldDisplaySwitchToRezOption) {
      this.addSwitchToSubRoute(subRoutes, "rez", "Rez");
    }
    if (this.shouldDisplaySwitchToPassOption) {
      this.addSwitchToSubRoute(subRoutes, "pass", "Pass");
    }
    if (this.shouldDisplaySwitchToOrderingOption) {
      this.addSwitchToSubRoute(subRoutes, "ordering", "Ordering");
    }
    if (this.shouldDisplaySwitchToQueueOption) {
      this.addSwitchToSubRoute(subRoutes, "queue", "Queue");
    }
    if (this.shouldDisplaySwitchToCitadelOption) {
      this.addSwitchToSubRoute(subRoutes, "wedding", "Citadel");
    }

    let parentRoute: RouteInfo = {
      alias: "switchmodule",
      path: "",
      title: "Switch Module",
      icon: "fa fa-map-signs",
      class: "has-sub",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: subRoutes
    };
    routes.push(parentRoute);
  }

  addSwitchToSubRoute(subRoutes, module, title) {
    let subRoute: RouteInfo = {
      path: "/module/switchto/" + module,
      title: "Switch To " + title,
      icon: "",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: []
    };
    subRoutes.push(subRoute);
  }

  refreshSwitchToFlags() {
    this.shouldDisplaySwitchToRezOption = this.checkSwitchToFlag("rez");
    this.shouldDisplaySwitchToPassOption = this.checkSwitchToFlag("pass");
    this.shouldDisplaySwitchToOrderingOption = this.checkSwitchToFlag("ordering");
    this.shouldDisplaySwitchToQueueOption = this.checkSwitchToFlag("queue");
    this.shouldDisplaySwitchToCitadelOption = this.checkSwitchToFlag("wedding");
  }

  checkSwitchToFlag(module) {
    if (this.currentSelectedModule == module) {
      return false;
    }
    //Here, We should check if mcompany have access
    var mCompany = this.lookupService.getMCompany();
    if (mCompany[module] != 1) {
      return false;
    }
    //Then check if user nfso 3ndo access
    return this.authService.accessToFohModule(module);
  }

  updatePendingRequestsNumber(newCounter) {
    var route = this.getRoutePathByAlias("requests");
    if (route != false) {
      route.badge = newCounter;
    }
  }

  bindReservationUpdaterEvents() {
    this.reservationUpdaterListener = this.reservationupdaterService
      .listen()
      .subscribe(event => {
        if (event.type == "pendingrequests-counter-update") {
          var newCounter = event.newCounter;
          this.updatePendingRequestsNumber(newCounter);
        }
      });
  }

  checkLicenseAlertStatus() {
    if (
      this.lookupService.licenseHaveAlertWarning &&
      !this.authService.isSuperAdmin()
    ) {
      this.showWarningMessage();
      setTimeout(() => {
        this.showWarningMessage();
      }, 300000);
    }
  }

  showWarningMessage() {
    warning(
      "License Expired",
      "We were not able to process the subscription renewal through the credit card in file, Please update it asap to avoid interruption of service."
    );
  }
}
import { RouteInfo } from './sidebar.metadata';

export const REZROUTES: RouteInfo[] = [
  {
    path: '/rez/dashboard', title: 'Dashboard', icon: 'foh-icon foh-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    alias: "reservations", path: '/rez/reservations', title: 'Reservations', icon: 'foh-reservation foh-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    alias: "requests", path: '/rez/requests', title: 'Requests', icon: 'foh-icon flag-noshow', class: '', badge: '', badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1', isExternalLink: false, submenu: [], permissions: ["permission_reservations_handlerequests"]
  },
  {
    alias: "customers", path: '', title: 'Profiles', icon: 'foh-icon foh-customers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/customers/list', title: 'Clients Profiles', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"] },
      {
        path: '/blackList', title: 'Black List', icon: '', class: '', badge: '', badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1', isExternalLink: false, submenu: []
      },
      { path: '/customers/birthday-calendar', title: 'Birthday Calendar', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: [] },
    ]
  },
 {
   path: '', title: 'Campaigns & Marketing', icon: 'fa fa-comments', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"],
   submenu: []
 },
  {
    alias: "requests", path: '/rez/requests', title: 'Requests', icon: 'foh-icon flag-noshow', class: '', badge: '', badgeClass: 'badge badge-pill badge-success float-right mr-1 mt-1', isExternalLink: false, submenu: [], permissions: ["permission_reservations_handlerequests"]
  },
  { path: '/rez/public-form', title: 'Reservation Form', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
  { path: '/rez/feedback-form', title: 'Feedback Form', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
  {
    path: '', title: 'Company Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/mcompany-setup/clientscats', title: 'Clients Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/sms', title: 'SMS Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/whatsapp', title: 'WhatsApp Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/slots', title: 'Slots', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/tags', title: 'Tags', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/terms', title: 'Terms & Conditions', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/rejection-reasons/read', title: 'Rejection Reasons', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
    ]
  },
  { path: '/rez/qrcode-gatechecker', title: 'Gate Checker', icon: 'fa fa-qrcode', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_gatechecker"], submenu: [] },
  {
    path: '', title: 'Campaign', icon: 'fa fa-comments', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"],
    submenu: [
      { path: '/campaign/sms-campaign/list', title: 'SMS Campaigns', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"], submenu: [] },
      { path: '/campaign/whatsApp-campaign/list', title: 'WhatsApp Campaigns', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_paymenthandle"], submenu: [] },
      { path: '/mcompany-setup/birthday-reminders', title: 'Birthday Greetings', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/sms-management/logs', title: 'SMS Logs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/whatsApp-management/logs', title: 'WhatsApp Logs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '', title: 'Reports', icon: 'fa fa-bar-chart', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reports"],
    submenu: [
      { path: '/reports/generate', title: 'Report Builder', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/reports/log', title: 'Generated Reports', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
  },
  {
    path: '', title: 'Operations', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_setup_mcompany"],
    submenu: [
      { path: '/operations/move-reservations', title: 'Move Reservations', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage"], submenu: [] },
      { path: '/operations/import-customers', title: 'Import Customers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage"], submenu: [] },
      { path: '/operations/import-guestlist', title: 'Import Guest lists', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage"], submenu: [] },
      { path: '/operations/import-reservation', title: 'Import Reservation', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage"], submenu: [] },
      { path: '/reports/opened-cheques', title: 'Opened Cheques', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_reservationcustomersmanage"] },
      { path: '/operations/log', title: 'Operation Logs', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/rez/qrcode-gatechecker', title: 'Gate Checker', icon: 'fa fa-qrcode', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_gatechecker"], submenu: [] },
    ]
  },
  {
        path: '', title: 'Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany", "permission_configs", "permission_tablesetup"],
    submenu: [
      { path: "", title: "System & Configurations", icon: "", class: "has-sub", badge: "", badgeClass: "",  isExternalLink: false, permissions: ["permission_setup_mcompany", "permission_configs", "permission_tablesetup"],
        submenu: [      
      { alias: "configs", path: "/rez/configs", queryParams: { tab: 'globalConfig' }, title: "General Config", icon: "", class: "", badge: "", badgeClass: "", isExternalLink: false, permissions: ["permission_configs"], submenu: [] },
      //fix
      {path: null, title: "Floor Setup", icon: "", class: "has-sub", badge: "", badgeClass: "", isExternalLink: false, permissions: ["permission_setup_mcompany", "permission_tablesetup", "permission_configs"],
      submenu: [
              { path: "/table-setup", title: "Layout Setup", icon: "", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [] },
              { path: "/mcompany-setup/table-types", title: "Table Types", icon: "", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [] }
            ]
      },
      
      { path: '/users-setup/superadmins', title: 'SuperAdmin SA', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/users-setup/users', title: 'Users List', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/users-setup/roles', title: 'Roles', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_usermanagement_roles"] },
      { path: '/users-setup/users/logs', title: "Users' Activity Log", icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      {path: '/changelog', title: 'Changes Log', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Messaging & Communication', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/mcompany-setup/templates/sms', title: 'SMS Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/whatsapp', title: 'WhatsApp Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
      { path: '/rez/configs', title: 'SMS Sender Name', queryParams: { tab: 'smsSender' }, icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
      { path: '/rez/configs', title: 'WhatsApp Sender Name', queryParams: { tab: 'whatsappSender' }, icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
          
    ]
  },
  {path: '', title: 'Reservation & Guest Management', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/rez/public-form', title: 'Reservation Form', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: null, title: 'Custom Configuration', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        { path: '/rez/configs', queryParams: { tab: 'frequentConfig' }, title: 'Frequent Visitors Configuration', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/rez/configs', queryParams: { tab: 'spenderConfig' }, title: 'Spending Categorization', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/rez/configs', queryParams: { tab: 'noShowConfig' }, title: 'No-Shows Configuration', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ] 
    },
    {  path: '/mcompany-setup/clientscats', title: 'Clients Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],},
    { path: '/mcompany-setup/tags', title: 'Tags', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/mcompany-setup/slots', title: 'Slots', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/mcompany-setup/terms', title: 'Terms & Conditions', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/mcompany-setup/rejection-reasons/read', title: 'Rejection Reasons', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  ],
  },
  {subscriptionItem: true, path: '', title: 'Billing & Transactions', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_superadmin"],
    submenu: [
      { subscriptionItem: true, path: '/subscriptions/payment-methods', title: 'Payment Methods', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { subscriptionItem:true, path: '/super-admin-board/recharge-sms', title: 'SMS Recharge', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { subscriptionItem:true, path: '/super-admin-board/recharge-whatsApp', title: 'WhatsApp Recharge', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { subscriptionItem: true, path: '/subscriptions/history', title: 'Transactions History', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {path: '', title: 'Integrations', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_superadmin"],
    submenu: [
      {  path: '', title: 'MICROS', icon: '', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          { path: '/integrations/configuration', title: 'Configuration', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { path: '/integrations/list', title: 'Mapping', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
        ] 
      },
      ]
  }
],
},
{ path: '/rez/help-center', title: 'Help Center', icon: 'ft-help-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
{ path: '', title: 'Switch Module', icon: 'fa fa-map-signs', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, isSuperAdmin: true,   submenu: [
        { path: '/module/switchto/rez', title: 'Switch to Rez', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/module/switchto/ordering', title: 'Switch to Ordering', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/module/switchto/queue', title: 'Switch to Queue', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
    ]
},
{ path: '/module/switchto/pass', title: 'Switch to Pass', icon: 'fa fa-exchange', class: '', badge: '', badgeClass: '', isExternalLink: false, isSuperAdmin: false, submenu: []},

  ];
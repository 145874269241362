import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Globals } from "../globals";
import { Reservation } from "./reservation";
import { Reservationstat } from "./reservationstat";
import { ReservedTable } from "./reservedtable";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { CachemanagerService } from "@foh/shared/services/cachemanager.service";
import { mutatSingleGuest, mutatSingleTicket } from "@foh/reservations/commonactions";

@Injectable({
  providedIn: "root"
})
export class ReservationsService {
  constructor(
    private globals: Globals,
    private http: HttpClient,
    private cachemanagerService: CachemanagerService
  ) { }

  private selectedFloorplanId: number | null = null;

  setSelectedFloorplanId(id: number) {
    this.selectedFloorplanId = id;
  }

  getSelectedFloorplanId(): number | null {
    return this.selectedFloorplanId;
  }

  /* New Version Of Streaming Reservations (Based on Offset )*/
  fullStreamWithLayout(params: any) {
    var observable = Observable.create((observer: any) => {
      //Check If cache is available,if there is cache then check cached version      
      var hasCachedReservations = this._fullRetrieveWithLayoutFromCache(params);
      if (hasCachedReservations != null) {
        this._streamCachedFound(observer, hasCachedReservations, params);
      } else {
        this._streamCachedNotFound(observer, params);
      }
    });
    return observable;
  }

  _streamCachedFound(observer, cachedResponse, params) {
    observer.next(cachedResponse);
    var copiedParams = JSON.parse(JSON.stringify(params));
    copiedParams.lightmode = true;
    copiedParams.loadedids = this.reservationsIds(cachedResponse.reservations);
    this.fullRetrieveWithLayoutFromDb(copiedParams).subscribe(
      (response: any) => {
        var newLiveData = this._mergeLightWithCachedFullRetrieveWithLayout(
          params,
          cachedResponse,
          response
        );
        newLiveData.replace = true;
        observer.next(newLiveData);
        //Update Version from Online.
        observer.complete();
      }
    );
  }

  _streamCachedNotFound(observer, params) {
    //If Cache Is Not Available, Stream From Database. Start with Offset 0.
    //Keep retriving till end of Records. After it is finished. Save to cache
    var reservations = [];
    var hasNext = false;
    var nextOffset = -1;
    this.startStreamWithLayoutFromDb(params).subscribe((response: any) => {
      observer.next(response);
      reservations.push(...response.reservations);
      this._saveLayout(params, response.layout);
      this._saveStats(params, response.stats);
      this._saveTables(params, response.tables);
      //=============
      //We got the response of the base url, lets check for more offset
      //=============
      if (response.hasNextOffset) {
        //If Has next, continue the stream
        this.continueReservationStream(
          observer,
          reservations,
          params,
          response.nextOffset
        );
      } else {
        this.streamReservationIsFinished(observer, reservations, params);
      }
    });
  }

  continueReservationStream(observer, reservations, params, nextOffset) {
    params.offset = nextOffset;
    this.continueStreamWithLayoutFromDb(params)
      .pipe(take(1))
      .subscribe((response: any) => {
        observer.next(response);
        reservations.push(...response.reservations);
        if (response.hasNextOffset) {
          this.continueReservationStream(
            observer,
            reservations,
            params,
            response.nextOffset
          );
        } else {
          this.streamReservationIsFinished(observer, reservations, params);
        }
      });
  }
  streamReservationIsFinished(observer, reservations, params) {
    //Reservations should only be cached when the stream is over.
    this._saveReservations(params, reservations);
    observer.complete();
  }
  startStreamWithLayoutFromDb(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_STARTSTREAMWLAYOUT,
      { params: params }
    );
  }
  continueStreamWithLayoutFromDb(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CONTINUESTREAMWLAYOUT,
      { params: params }
    );
  }
  /*End of New Version Of Streaming Reservations*/

  /*New Version Of Streaming GL */
  streamGuestlist(params) {
    var observable = Observable.create((observer: any) => {
      this.continueGlStream(observer, params, 0);
    });
    return observable;
  }
  continueGlStream(observer, params, nextOffset) {
    params.offset = nextOffset;
    this.maintainStreamGuestListDb(params)
      .pipe(take(1))
      .subscribe((response: any) => {
        response.list.filter(record => {
          mutatSingleGuest(record);
        });
        observer.next(response);
        if (response.hasNextOffset) {
          this.continueGlStream(observer, params, response.nextOffset);
        } else {
          this.streamGlIsFinished(observer, params);
        }
      });
  }
  streamGlIsFinished(observer, params) {
    observer.complete();
  }
  maintainStreamGuestListDb(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_STREAM_START,
      { params: params }
    );
  }
  /*End of New Version Of Streaming GL */
  /*New Version Of Streaming TL */
  streamTicketlist(params) {
    var observable = Observable.create((observer: any) => {
      this.continueTlStream(observer, params, 0);
    });
    return observable;
  }
  continueTlStream(observer, params, nextOffset) {
    params.offset = nextOffset;
    this.maintainStreamTicketListDb(params)
      .pipe(take(1))
      .subscribe((response: any) => {
        response.list.filter(record => {
          mutatSingleTicket(record);
        });
        observer.next(response);
        if (response.hasNextOffset) {
          this.continueTlStream(observer, params, response.nextOffset);
        } else {
          this.streamTlIsFinished(observer, params);
        }
      });
  }
  streamTlIsFinished(observer, params) {
    observer.complete();
  }
  maintainStreamTicketListDb(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_STREAM_START,
      { params: params }
    );
  }
  /*End of New Version Of Streaming TL */
  /*QR SCan Codes*/
  getQRCodesFromReservationRefCode(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_QRCODESLIST, { params: params });
  }
  validateQRCode(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_VALIDATE_CODE, params);
  }
  checkInQRCode(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_CHECKIN_CODE, params);
  }
  /*END of QR SCan Codes*/
  /*QR Invitations*/
  getMetadataOfQRInvitationDriver(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_QRINVITATIONS_METADATA, { params: params });
  }
  createQRInvitationLink(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_QRINVITATIONS_CREATE, params);
  }
  getQRInvitationFromReservationRefCode(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_QRINVITATIONS_RETRIEVE, { params: params });
  }
  /*END of QR Invitations*/

  fullRetrieveWithLayout(params: {}) {
    var observable = Observable.create((observer: any) => {
      var hasCachedReservations = this._fullRetrieveWithLayoutFromCache(params);
      if (hasCachedReservations == null) {
        this.fullRetrieveWithLayoutFromDb(params).subscribe((response: any) => {
          observer.next(response);
          this._saveFullRetrieveWithLayoutCache(params, response);
          observer.complete();
        });
      } else {
        //Retrieve Cached Version.
        observer.next(hasCachedReservations);

        var copiedParams = JSON.parse(JSON.stringify(params));
        copiedParams.lightmode = true;
        copiedParams.loadedids = this.reservationsIds(
          hasCachedReservations.reservations
        );
        this.fullRetrieveWithLayoutFromDb(copiedParams).subscribe(
          (response: any) => {
            var newLiveData = this._mergeLightWithCachedFullRetrieveWithLayout(
              params,
              hasCachedReservations,
              response
            );
            observer.next(newLiveData);
            //Update Version from Online.
            observer.complete();
          }
        );
      }
    });
    return observable;
  }

  fullRetrieveWithLayoutFromDb(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_FULLRETRIEVEWLAYOUT,
      { params: params }
    );
  }

  fullRetrieve(params: {}) {
    var observable = Observable.create((observer: any) => {
      var hasCachedReservations = this._fullRetrieveFromCache(params);
      if (hasCachedReservations == null) {
        this.fullRetrieveFromDb(params).subscribe((response: any) => {
          observer.next(response);
          this._saveFullRetrieveCache(params, response);
          observer.complete();
        });
      } else {
        //Retrieve Cached Version.
        observer.next(hasCachedReservations);

        var copiedParams = JSON.parse(JSON.stringify(params));
        copiedParams.lightmode = true;
        copiedParams.loadedids = this.reservationsIds(
          hasCachedReservations.reservations
        );
        this.fullRetrieveFromDb(copiedParams).subscribe((response: any) => {
          var newLiveData = this._mergeLightWithCachedFullRetrieve(
            params,
            hasCachedReservations,
            response
          );
          observer.next(newLiveData);
          //Update Version from Online.
          observer.complete();
        });
      }
    });
    return observable;
  }
  fullRetrieveFromDb(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_FULLRETRIEVE,
      { params: params }
    );
  }

  retrieve(params: {}) {
    return this.http.get<Reservation[]>(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_LIST,
      { params: params }
    );
  }
  retrieveRequests(params: {}) {
    return this.http.get(this.globals.WEBAPI + this.globals.URL_REQUESTS_LIST, {
      params: params
    });
  }
  retrieveAllRequests(params) {
    return this.http.get(this.globals.WEBAPI + this.globals.URL_REQUESTS_LIST, {
      params: params
    });
  }
  retrieveNewRequest(id) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_REQUESTS_SINGLE,
      { id: id }
    );
  }
  retrieveReservationsAndRequests(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONSANDREQUESTS_LIST,
      { params: params }
    );
  }

  reservedTables(params: {}) {
    return this.http.get<ReservedTable[]>(
      this.globals.WEBAPI + this.globals.URL_RESERVEDTABLES_LIST,
      { params: params }
    );
  }
  retrieveStats(params: {}) {
    return this.http.get<Reservationstat[]>(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_STATS,
      { params: params }
    );
  }
  retrieveAllSlotsStats(params: {}) {
    return this.http.get<Reservationstat[]>(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_ALLSLOTSSTATS,
      { params: params }
    );
  }
  create(record: {}) {
    return this.http.post(
      this.globals.WEBAPI +
      this.globals.URL_RESERVATIONS_CREATE,
      record
    );
  }
  edit(record: {}) {
    return this.http.post<Reservation>(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_EDIT,
      record
    );
  }
  manageRequest(record: {}) {
    return this.http.post<Reservation>(
      this.globals.WEBAPI + this.globals.URL_REQUESTS_MANAGE,
      record
    );
  }

  confirm(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CONFIRM,
      { params: params }
    );
  }

  sendSelectedRequests(reservationIds: number[]) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CONFIRM,
      reservationIds
    );
  }

  massConfirm(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_MASSCONFIRM,
      params
    );
  }
  massQRInviation(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_MASSQRINVITATION,
      params
    );
  }
  cancel(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CANCEL,
      { params: params }
    );
  }
  close(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CLOSE,
      { params: params }
    );
  }

  generateReservationReceiptPdf(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_GENERATERECEIPTPDF,
      { params: params }
    );
  }
  sendNotificaion(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_SENDNOTIFICATION,
      { params: params }
    );
  }

  allocateTables(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_FOHTABLES_ALLOCATE,
      params
    );
  }
  deallocateTables(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_FOHTABLES_DEALLOCATE,
      params
    );
  }
  deallocateAllTables(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_FOHTABLES_DEALLOCATEALL,
      params
    );
  }

  export(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_EXPORT,
      { params: params }
    );
  }

  retrieveDepositList(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_DEPOSITLIST_RETRIEVE,
      { params: params }
    );
  }
  updateDepositTotalRequired(params) {
    return this.http.post(
      this.globals.WEBAPI +
      this.globals.URL_RESERVATIONS_DEPOSITLIST_UPDATETOTALREQUIRED,
      params
    );
  }
  addDepositRecord(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_DEPOSITLIST_ADDRECORD,
      params
    );
  }
  sendDepositPaymentLink(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_DEPOSIT_PAYMENTLINK,
      { params: params }
    );
  }

  activityLogs(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_ACTIVITYLOGS_RETRIEVE,
      { params: params }
    );
  }

  /*SMS*/
  messageLogs(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_MESSAGESLOGS_RETRIEVE,
      { params: params }
    );
  }

  /*Guestlist*/
  retrieveGuestList(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_LIST,
      { params: params }
    );
  }
  retrieveAllReservationsGuestlist(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_ALLRESERVATIONS,
      { params: params }
    );
  }
  addGuest(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_CREATE,
      params
    );
  }
  removeGuest(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_REMOVE,
      params
    );
  }
  switchOverrideOff(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_GUESTLIST_SWITCHOVERRIDEOFF,
      { params: params }
    );
  }

  /*Ticketlist*/
  retrieveTicketList(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_LIST,
      { params: params }
    );
  }
  retrieveAllReservationsTicketlist(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_ALLRESERVATIONS,
      { params: params }
    );
  }
  addTicket(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_CREATE,
      params
    );
  }
  removeTicket(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_REMOVE,
      params
    );
  }

  updatePaidStatus(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_PAIDSTATUS,
      params
    );
  }

  updateTicketCat(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_UPDATETICKETCAT,
      params
    );
  }

  resendCode(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_RESENDCODE,
      params
    );
  }
  sendPaymentUrl(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_SENDPAYMENTURL,
      params
    );
  }
  sendPaymentUrlToHost(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_SENDPAYMENTURLTOHOST,
      params
    );
  }

  /*Search Reservations*/
  searchReservations(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_SEARCH,
      params
    );
  }

  /*Search Requests*/
  searchRequests(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_REQUESTS_SEARCH,
      params
    );
  }

  searchGuestlists(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_GUESTLISTS_SEARCH,
      params
    );
  }

  searchTickets(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETS_SEARCH,
      params
    );
  }

  updateGuestStatus(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_GUESTLISTS_UPDATESTATUS,
      params
    );
  }

  convertToGenericGuestlist(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_GUESTLISTS_CONVERTTOGENERICGUESTLIST,
      params
    );
  }

  updateTicketStatus(params: {}) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_TICKETLIST_UPDATESTATUS,
      params
    );
  }

  /*Legacy Requests*/
  fullRetrieveLegacy(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_FULLRETRIEVEW_LEGACY,
      { params: params }
    );
  }

  loadNewLayout(params: {}) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_RETRIEVELAYOUT_LEGACY,
      { params: params }
    );
  }

  /*Legacy Requests End*/

  /*Cache Handling Methods*/
  _saveFullRetrieveWithLayoutCache(params, response: any) {
    this._saveReservations(params, response.reservations);
    this._saveLayout(params, response.layout);
    this._saveStats(params, response.stats);
    this._saveTables(params, response.tables);
  }

  _saveFullRetrieveCache(params, response: any) {
    this._saveReservations(params, response.reservations);
    this._saveStats(params, response.stats);
    this._saveTables(params, response.tables);
  }
  _saveReservations(params, data) {
    this.cachemanagerService.save(
      this.cachemanagerService.TYPE_RESERVATIONS,
      params,
      data
    );
  }
  _saveLayout(params, data) {
    this.cachemanagerService.save(
      this.cachemanagerService.TYPE_LAYOUT,
      params,
      data
    );
  }
  _saveStats(params, data) {
    this.cachemanagerService.save(
      this.cachemanagerService.TYPE_STATS,
      params,
      data
    );
  }
  _saveTables(params, data) {
    this.cachemanagerService.save(
      this.cachemanagerService.TYPE_TABLES,
      params,
      data
    );
  }

  _saveGuestlist(params, data) {
    this.cachemanagerService.save(
      this.cachemanagerService.TYPE_GUESTSLIST,
      params,
      data
    );
  }

  _fullRetrieveWithLayoutFromCache(params) {
    var reservations = this._retrieveReservations(params);
    var stats = this._retrieveStats(params);
    var layout = this._retrieveLayout(params);
    var tables = this._retrieveTables(params);
    if (
      reservations == null ||
      stats == null ||
      layout == null ||
      tables == null
    ) {
      return null;
    }
    var response: any = {
      reservations: reservations.data,
      stats: stats.data,
      layout: layout.data,
      tables: tables.data,
      guestlist: [],
      pendingRequestsCounter: 0,
      ticketslist: []
    };
    return response;
  }
  _fullRetrieveFromCache(params) {
    var reservations = this._retrieveReservations(params);
    var stats = this._retrieveStats(params);
    var tables = this._retrieveTables(params);
    if (reservations == null || stats == null || tables == null) {
      return null;
    }
    var response: any = {
      reservations: reservations.data,
      stats: stats.data,
      tables: tables.data,
      guestlist: [],
      ticketslist: []
    };
    return response;
  }
  _retrieveReservations(params) {
    return this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_RESERVATIONS,
      params
    );
  }
  _retrieveLayout(params) {
    return this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_LAYOUT,
      params
    );
  }
  _retrieveStats(params) {
    return this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_STATS,
      params
    );
  }
  _retrieveTables(params) {
    return this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_TABLES,
      params
    );
  }

  _retrieveGuestLists(params) {
    return this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_GUESTSLIST,
      params
    );
  }

  reservationsIds(reservations) {
    var ids = [];
    for (var i = 0; i < reservations.length; i++) {
      ids.push(reservations[i].id);
    }
    return ids.join("_");
  }
  _mergeLightWithCachedFullRetrieveWithLayout(params, cachedData, liveData) {
    //update stats
    this._saveStats(params, liveData.stats);
    //update Layouts
    this._saveLayout(params, liveData.layout);
    //update Tables
    this._saveTables(params, liveData.tables);

    var newReservations = this.updateReservationsWithLightReservations(
      cachedData.reservations,
      liveData.reservations,
      liveData.newReservations
    );
    this._saveReservations(params, newReservations);
    var response: any = {
      reservations: newReservations,
      stats: liveData.stats,
      layout: liveData.layout,
      tables: liveData.tables,
      ticketTypes: liveData.ticketTypes,
      pendingRequestsCounter: liveData.pendingRequestsCounter,
      guestlist: [],
      ticketslist: [],
    };
    return response;
  }
  _mergeLightWithCachedFullRetrieve(params, cachedData, liveData) {
    //update stats
    this._saveStats(params, liveData.stats);
    //update Tables
    this._saveTables(params, liveData.tables);
    var newReservations = this.updateReservationsWithLightReservations(
      cachedData.reservations,
      liveData.reservations,
      liveData.newReservations
    );
    var response: any = {
      reservations: newReservations,
      stats: liveData.stats,
      tables: liveData.tables,
      guestlist: [],
      ticketslist: []
    };
    return response;
  }
  updateReservationsWithLightReservations(
    cachedData,
    liveData,
    appendReservations
  ) {
    var newReservations = [];
    for (var j = 0; j < liveData.length; j++) {
      for (var i = 0; i < cachedData.length; i++) {
        if (cachedData[i].id == liveData[j].id) {
          liveData[j].customer_profile = cachedData[i].customer_profile;
          break;
        }
      }
      var reservationString = JSON.stringify(liveData[j]);
      var reservationNewObject = JSON.parse(reservationString);
      newReservations.push(reservationNewObject);
    }
    return newReservations.concat(appendReservations);
  }

  updateReservationAtCache(params, newReservation) {
    //Retrieve Cache
    var reservations = null;
    var cachedReservations = this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_RESERVATIONS,
      params
    );
    if (cachedReservations != null) {
      reservations = cachedReservations.data;
    }
    if (reservations != null) {
      var newReservations = this._updateSingleReservationAtArray(
        newReservation,
        reservations
      );
      this._saveReservations(params, newReservations);
    }
  }

  _updateSingleReservationAtArray(singleReservation, reservations) {
    for (var i = 0; i < reservations.length; i++) {
      if (reservations[i].id == singleReservation.id) {
        var customerProfile = { ...reservations[i].customer_profile, ...singleReservation.customer_profile };
        singleReservation.customer_profile = customerProfile;
        reservations[i] = {
          ...reservations[i],
          ...singleReservation
        };
        break;
      }
    }
    return reservations;
  }

  updateAttendanceOfListAtCache(params, attendanceList) {
    var fullList = null;
    var cachedList = this.cachemanagerService.retrive(
      this.cachemanagerService.TYPE_GUESTSLIST,
      params
    );
    if (cachedList != null) {
      fullList = cachedList.data;
    }
    if (fullList != null) {
      var newFullList = this._mergeAttendanceStatusWithList(
        attendanceList,
        fullList
      );
      this._saveGuestlist(params, newFullList);
    }
  }

  _mergeAttendanceStatusWithList(attendanceList, fullList) {
    for (var i = 0; i < attendanceList.length; i++) {
      for (var j = 0; j < fullList.length; j++) {
        if (attendanceList[i].id == fullList[j].id) {
          fullList[j].gender = attendanceList[i].gender;
          fullList[j].attended = attendanceList[i].attended;
          break;
        }
      }
    }
    return fullList;
  }
  /*End Of Caching Methods*/

  /*Start of the Views Switching*/
  currentDefaultView() {
    var currentView = localStorage.getItem(
      this.globals.STORAGE_PREFIX + "cview"
    );
    if (currentView == null) {
      return "modern";
    }
    return currentView;
  }
  setNewDefaultView(newview) {
    localStorage.setItem(this.globals.STORAGE_PREFIX + "cview", newview);
  }

  /*Queue */
  fullQueueWithLayout(params: any) {
    var observable = Observable.create((observer: any) => {
      this.http.get(
        this.globals.WEBAPI + this.globals.URL_QUEUE_LIST,
        { params: params }
      ).subscribe((response: any) => {
        observer.next(response);
      });
    });
    return observable;
  }

  /*Citadel */
  uploadWeddingFiles(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_UPLOADWEDDINGFILES, params

    );
  }
  changeWeddingStatus(id, newStatus, sendNotificationFlag) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_CHANGEWEDDINGSTATUS,
      { id: id, newstatus: newStatus, sendsms: sendNotificationFlag }
    );
  }
  getPrepDatesForReservation(params) {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_GETPREPRATIONDATESFORRESERVATION,
      { params: params }
    );
  }
  getPendingCanceledRequests() {
    return this.http.get(
      this.globals.WEBAPI + this.globals.URL_RESERVATIONS_WEDDINGCANCELATIONREQUESTS
    );
  }

  updateAddonOfReservation(id, params) {
    return this.http.post(
      this.globals.WEBAPI + 'wedding/admin/reservations/update-addons', params);
  }
  removeCancelRequest(params) {
    return this.http.post(
      this.globals.WEBAPI + 'wedding/admin/reservations/undo-cancel', params);
  }

  checkBeforeUpdate(params) {
    return this.http.post(
      this.globals.WEBAPI + 'wedding/admin/reservations/check-before-update', params);
  }
  updateWeddingParams(params) {
    return this.http.post(
      this.globals.WEBAPI + 'wedding/admin/reservations/update', params);
  }
}

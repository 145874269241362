import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Injectable } from "@angular/core";
import { CustomerauthService } from "@foh/shared/auth/customerauth.service";

@Injectable()
export class CustomerGuestGuard implements CanActivate {
  constructor(private customerauthService: CustomerauthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      var domain = route.params.token;
      if (typeof domain === "undefined" || domain === null || domain === "") {
        return false;
      }
      this.customerauthService.refresh(domain);
    if (this.customerauthService.isAuthenticated()) {
      this.router.navigate(["guest/myprofile/"+domain]);
      return false;
    }
    return true;
  }
}

import { Router, ActivatedRoute } from "@angular/router";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "../../globals";
//import { CookieService } from "ngx-cookie-service";

@Injectable()
export class CustomerauthService {
  token: string;
  user: any;
  domain: string;

  constructor(private http: HttpClient, private globals: Globals,private router:Router) {
    this.token = null;
    this.user = null;
    this.domain = null;
  }

  refresh(domain) {
    this.domain = domain;
    this.user = this.getUser();
  }

  signupUser(params) {
    //your code for signing up the new user
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_CUSTOMERPROFILE_SIGNUP,
      params
    );
  }

  signinUser(params) {
    return this.http.post(
      this.globals.WEBAPI + this.globals.URL_CUSTOMERPROFILE_SIGNIN,
      params
    );
  }

  logInUser(data: {}) {
    this.domain = data["domain"];
    this.token = data["token"];
    //this.cookieService.set( this.getTokenKey(this.domain) , this.token,30,"/",this.globals.DOMAIN,this.globals.SECURECOOKIE);
    localStorage.setItem(this.getTokenKey(this.domain), this.token);
    this.user = data["customerProfile"];
    localStorage.setItem(
      this.getCustomerKey(this.domain),
      JSON.stringify(this.user)
    );
  }

  getTokenKey(domain) {
    var string =
      this.globals.STORAGE_PREFIX +
      this.globals.STORAGE_CUSTOMERTOKEN +
      "_" +
      domain;
    return string;
  }

  getCustomerKey(domain) {
    var string =
      this.globals.STORAGE_PREFIX +
      this.globals.STORAGE_CUSTOMER +
      "_" +
      domain;
    return string;
  }

  logout() {
    this.token = null;
    this.user = null;
    localStorage.removeItem(this.getCustomerKey(this.domain));
    localStorage.removeItem(this.getTokenKey(this.domain));
    //this.cookieService.delete( this.getTokenKey(this.domain)  ,"/");
    this.domain = null;
  }

  getToken() {
    //return this.cookieService.get( this.getTokenKey(this.domain) );
    return localStorage.getItem(this.getTokenKey(this.domain));
  }
  getUser() {
    return JSON.parse(localStorage.getItem(this.getCustomerKey(this.domain)));
  }

  getDomain(){
    return this.domain;
  }

  isAuthenticated() {
    if (this.getToken() == null) return false;
    return true;
  }
  handleHttpErrors(err){
    switch(err.status){
      case 401:
        if(err.error.error === "token_expired")
        {
          this.logout();
          this.router.navigate(['guest/login/'+this.domain]);
        }
        break;
    }
  }
}

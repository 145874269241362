import { NgModule, APP_INITIALIZER, enableProdMode } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptorService } from "./shared/auth/token-interceptor.service";
import { AuthService } from "./shared/auth/auth.service";
import { EnvConfigurationService } from "./shared/auth/env-configuration.service";
import { CustomerauthService } from "./shared/auth/customerauth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { CustomerAuthGuard } from "./shared/auth/customer-auth-guard.service";
import { GuestGuard } from "./shared/auth/guest-guard.service";
import { CustomerGuestGuard } from "@foh/shared/auth/customer-guest-guard.service";
import { LookupResolver } from "./shared/resolvers/lookup.resolver";
import { SocketResolver } from "./shared/resolvers/socket.resolver";
import { Globals } from "./globals";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";
import { GridsterModule } from "angular-gridster2";
import * as $ from "jquery";
import { CookieService } from "ngx-cookie-service";
import { ModuleSwitcherComponent } from "./module-switcher/module-switcher.component";
import { LandingPageComponent } from './landing-page/landing-page.component';
import { environment } from "environments/environment";
import { SystemUpdateModalComponent } from './system-update-modal/system-update-modal.component';
import { FeedbackComponent } from './feedback/feedback.component';


if (environment.production) {
  enableProdMode();
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    ModuleSwitcherComponent,
    LandingPageComponent,
    SystemUpdateModalComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    NgbModule.forRoot(),
    HttpClientModule,
    FormsModule,
    GridsterModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyA3fp_LVQLqO6RKYe00rxMWGHobKsf4R1A",
      libraries: ["places", "drawing"]
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envConfigService: EnvConfigurationService) => () =>
        envConfigService.load(),
      deps: [EnvConfigurationService, Globals],
      multi: true
    },
    Globals,
    AuthService,
    CustomerauthService,
    AuthGuard,
    CustomerAuthGuard,
    GuestGuard,
    CustomerGuestGuard,
    FohmoduleSelectorService,
    SocketResolver,
    LookupResolver,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [SystemUpdateModalComponent]
})
export class AppModule { }

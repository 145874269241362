import { Injectable } from '@angular/core';
import { SocketsService } from '../services/sockets.service';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';

@Injectable()
export class SocketResolver implements Resolve<any> {
  constructor(private socketsService: SocketsService) {}

  resolve() {      
      return this.socketsService.connect();
  }
}
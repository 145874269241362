import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '@foh/globals';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminBoardService {

  constructor(private http: HttpClient, private globals: Globals) { }

  listAllMCompanies(): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_MCOMPANY_LIST}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

  showMCompany(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_MCOMPANY_GET}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

  createMCompany(data: any, file: File): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_MCOMPANY_CREATE}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    if (file) {
      body.append('logo', file, file.name);
    }

    return this.http.post(url, body, { headers });
  }

  updateMCompany(id: string, data: any, file: File): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_MCOMPANY_UPDATE}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    if (file) {
      body.append('logo', file, file.name);
    }
    return this.http.post(url, body, { headers });
  }

  deleteMCompanyLogo(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_MCOMPANY_DELETE_LOGO}/${id}/delete-logo`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.delete(url, { headers });
  }

  // Venues methods

  // Method to list all venues for a specific company
  listAllVenues(): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_VENUE_LIST}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

  showVenue(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_VENUE_GET}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }


  // Method to create a new venue with optional logo file
  createVenue(data: any, file: File): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_VENUE_CREATE}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    if (file) {
      body.append('logo', file, file.name);
    }

    return this.http.post(url, body, { headers });
  }

  // Method to update an existing venue with optional logo file
  updateVenue(id: string, data: any, file: File): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_VENUE_UPDATE}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    if (file) {
      body.append('logo', file, file.name);
    }
    return this.http.post(url, body, { headers });
  }

  // Method to delete a venue's logo
  deleteVenueLogo(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_VENUE_DELETE_LOGO}/${id}/delete-logo`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.delete(url, { headers });
  }

  // Beanches methods

  listAllBranches(): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_BRANCH_LIST}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

  showBranch(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_BRANCH_GET}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

  // Method to create a new branch with optional logo file
  createBranch(data: any): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_BRANCH_CREATE}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    return this.http.post(url, body, { headers });
  }

  // Method to update an existing branch with optional logo file
  updateBranch(id: string, data: any): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_BRANCH_UPDATE}/${id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    const body = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        body.append(key, data[key]);
      }
    }
    return this.http.post(url, body, { headers });
  }

  // Method to delete a branch's logo
  deleteBranchLogo(id: string): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_BRANCH_DELETE_LOGO}/${id}/delete-logo`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.delete(url, { headers });
  }

  // SMS

  private SMSAPI = 'https://smsvas.vlserv.com/VLSMSPlatformResellerAPI/NewSendingAPI/api/SMSSender/SendSMS';
  sendSMS(data: any): Observable<any> {
    return this.http.post(`${this.SMSAPI}/send-sms`, data);
  }

  rechargeSMS(data: any): Observable<any> {
    const url = `${this.globals.WEBAPI}${this.globals.URL_SMSRECHARGE}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.post(url, data, { headers });
  }

  getSMSTransactions(pageNumber: number, limit: number, companyId: string, dateFrom?: string, dateTo?: string): Observable<any> {
    let url = `${this.globals.WEBAPI}${this.globals.URL_SMSTRANSACTIONS}?pageNumber=${pageNumber}&limit=${limit}`;

    if (companyId !== 'all') {
      url += `&mcompany_id=${companyId}`;
    }

    if (dateFrom) {
      url += `&date_from=${dateFrom}`;
    }

    if (dateTo) {
      url += `&date_to=${dateTo}`;
    }

    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.globals.STORAGE_TOKEN}`);
    return this.http.get(url, { headers });
  }

}

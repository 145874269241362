import { Component, OnInit } from "@angular/core";
import { LookupService } from "@foh/shared/services/lookup.service";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
  styleUrls: ["./full-layout.component.scss"]
})
export class FullLayoutComponent {
  options = {
    direction: "ltr"
  };
  showSmsWarning: boolean = false;
  showLicenseWarning: boolean = false;
  showInvoiceAlertWarning: boolean = false;
  nextBillingDate: any;
  currentSelectedModule: string;
  constructor(
    private lookupService: LookupService,
    private fohmoduleSelectorService: FohmoduleSelectorService
  ) { }

  ngOnInit() {
    var mCompany = this.lookupService.mcompany;
    if (mCompany.usessms) {
      if (this.lookupService.vars["smsBalance"] <= 100) {
        this.showSmsWarning = true;
      }
    }
    this.showLicenseWarning = this.lookupService.licenseHaveTextWarning;
    this.showInvoiceAlertWarning =  this.lookupService.invoiceAlertWarning;
    this.nextBillingDate = this.lookupService.nextBillingDate;
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();
  }
}

import {
  getUnknownGuestObject,
  formatDateCustomObjectToSql
} from "@foh/shared/services/utils";

export function getReservationParams(date,branch,slot,event,isHourBased,isEventBased,forceSlot,isWeddingMode,dateTo,scope) {  
  var params: any = {
    date: formatDateCustomObjectToSql(date),
    branch_id: branch.id
  };
  if(isWeddingMode){
    params.dateto = formatDateCustomObjectToSql(dateTo);
    params.scope = scope;
    params.mode = "wedding";
    return params;
  }
  if (isEventBased) {
    params.event_id = event.id;
    params.mode = "eventbased";
  } else {
    if (isHourBased && forceSlot) {
      params.slot_id = -99;
      params.mode = "hourbased";
    }
    if (!isHourBased) {
      params.slot_id = slot.id;
      params.mode = "slotbased";
    }
  }
  return params;
}

export function handleReservationStatusShortCut(
  newStatus,
  reservation,
  reservationsActionsService,
  reservationupdaterService,
  confirmEmitter,
  cancelEmitter
) {
  switch (newStatus) {
    case "confirm":
      confirm(reservation, reservationsActionsService, confirmEmitter);
      break;
    case "forceconfirm":
      forceConfirm(reservation, reservationsActionsService, confirmEmitter);
      break;
    case "attend":
      reservationupdaterService.onAttendanceOpen(reservation);
      break;
    case "close":
      reservationupdaterService.onCloseOpen(reservation);
      break;
    case "cancel":
      cancel(reservation, reservationsActionsService, cancelEmitter);
      break;
    case "forcecancel":
      forceCancel(reservation, reservationsActionsService, cancelEmitter);
      break;
    case "noshow":
      markAsNoShow(reservation, reservationsActionsService, cancelEmitter);
      break;
  }
}

export function confirm(
  reservation,
  reservationsActionsService,
  confirmEmitter
) {
  reservationsActionsService
    .displayConfirmationMessage(reservation)
    .subscribe((response: any) => {
      if (!response.confirmed) {
        return;
      }
      confirmEmitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: false,
        templateid: response.overrideTemplateId,
        convertToGenericList: response.convertToGenericList,
      });
    });
}
export function forceConfirm(
  reservation,
  reservationsActionsService,
  confirmEmitter
) {
  reservationsActionsService
    .displayForceConfirmationMessage(reservation)
    .subscribe((response: any) => {
      if (!response.confirmed) {
        return;
      }
      confirmEmitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: true,
        templateid: response.overrideTemplateId,
        convertToGenericList:false
      });
    });
}

export function attend(reservation, reservationupdaterService) {
  reservationupdaterService.onAttendanceOpen(reservation);
}
export function highlightCode(reservation, code, reservationupdaterService) {
  reservationupdaterService.onAttendanceOpenWithCodeHighlight(
    reservation,
    code
  );
}
export function close(reservation, reservationupdaterService) {
  reservationupdaterService.onCloseOpen(reservation);
}
export function cancel(reservation, reservationsActionsService, cancelEmitter) {
  reservationsActionsService
    .displayCancelMessage(reservation)
    .subscribe((response: any) => {
      if (!response.canceled) {
        return;
      }
      cancelEmitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        sendEmail: response.sendEmail,
        forcesend: false,
        templateid: response.overrideTemplateId,
        noshow: false ,
        cancel_reason_id : response.cancel_reason_id
      });
    });
}
export function forceCancel(
  reservation,
  reservationsActionsService,
  cancelEmitter
) {
  reservationsActionsService
    .displayForceCancelMessage(reservation)
    .subscribe((response: any) => {
      if (!response.canceled) {
        return;
      }
      cancelEmitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: true,
        templateid: response.overrideTemplateId,
        noshow: false
      });
    });
}
export function weddingDetails(reservation, reservationupdaterService) {
  reservationupdaterService.onWeddingDetailsOpen(reservation);
}
export function weddingAddons(reservation, reservationupdaterService) {
  reservationupdaterService.onWeddingAddonsOpen(reservation);
}
export function markAsNoShow(
  reservation,
  reservationsActionsService,
  cancelEmitter
) {
  reservationsActionsService
    .displayNoShowMessage(reservation)
    .subscribe((response: any) => {
      if (!response.canceled) {
        return;
      }
      cancelEmitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: false,
        templateid: response.overrideTemplateId,
        noshow: true
      });
    });
}
export function sendNotification(
  reservation,
  reservationsActionsService,
  emitter
) {
  reservationsActionsService
    .displayNotificationMessage(reservation)
    .subscribe((response: any) => {
      if (!response.sendNotification) {
        return;
      }

      if (response.overrideTemplateId == "-1") {
        return;
      }

      emitter.emit({
        reservation: reservation,
        templateid: response.overrideTemplateId
      });
    });
}
export function cancelTicketOrder(
  reservation,
  reservationsActionsService,
  emitter
) {
  reservationsActionsService
    .displayCancelTicketOrderConfirm(reservation)
    .subscribe((response: any) => {
      if (!response.canceled) {
        return;
      }
      emitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: false
      });
    });
}
export function forceCancelTicketOrder(
  reservation,
  reservationsActionsService,
  emitter
) {
  reservationsActionsService
    .displayCancelTicketOrderConfirm(reservation)
    .subscribe((response: any) => {
      if (!response.canceled) {
        return;
      }
      emitter.emit({
        reservation: reservation,
        sendSms: response.sendSms,
        forcesend: true
      });
    });
}
export function resendRejectionMessageOfTicketOrder(
  reservation,
  reservationsActionsService,
  emitter
) {
  reservationsActionsService
    .displayResendRejectionMessage(reservation)
    .subscribe((response: any) => {
      if (!response.confirmed) {
        return;
      }
      emitter.emit({
        reservation: reservation
      });
    });
}
export function editReservation(reservation, reservationupdaterService) {
  reservationupdaterService.onEditReservationOpen(reservation);
}
export function managePassRequest(reservation, reservationupdaterService) {
  reservationupdaterService.onManagePassRequestOpen(reservation);
}
export function allocateViaDdl(reservation, fohtablesselectorService) {
  fohtablesselectorService.openAllocateDdlModal(reservation);
}
export function openGuestlist(reservation, reservationupdaterService) {
  reservationupdaterService.onGuestlistOpen(reservation);
}
export function openTicketlist(reservation, reservationupdaterService) {
  reservationupdaterService.onTicketlistOpen(reservation);
}
export function openDeposit(reservation, reservationupdaterService) {
  reservationupdaterService.onManageDepositOpen(reservation);
}
export function openManagePayment(reservation, reservationupdaterService) {
  reservationupdaterService.onManagePaymentOpen(reservation);
}
export function openManageTags(reservation, reservationupdaterService) {
  reservationupdaterService.onManageTagsOpen(reservation);
}
export function openManageQrInv(reservation, reservationupdaterService) {
  reservationupdaterService.onManageQrInvOpen(reservation);
}
export function openActivityLog(reservation, reservationupdaterService) {
  reservationupdaterService.onActivityLogOpen(reservation);
}
export function openMessagesLog(reservation, reservationupdaterService) {
  reservationupdaterService.onMessagesLogOpen(reservation);
}

export function mutatSingleGuest(record) {
  mutatCommonListRecord(record);
}
export function mutatSingleTicket(record) {
  mutatCommonListRecord(record);
  if ((typeof record.paid === "undefined" || record.paid != 1) && (typeof record.free === "undefined" || record.free != 1) ) {
    record.code = "-";
  }
}
export function mutatCommonListRecord(record) {
  if (record.fname == null) {
    record.fname = record.cfname;
  }
  if (record.lname == null) {
    record.lname = record.clname;
  }
  if (record.pname == null && record.cpname != null && record.cpname != '') {
    record.pname = record.cpname;
  }
  if (record.gender == null) {
    record.gender = record.cgender;
  }
  if (record.fblink == null) {
    record.fblink = record.cfblink;
  }
  //record.mobileno = record.mobileno;
}
export function mutatReservationRecord(record) {
  record.tableName = "-";
  record.joinedTablesNames = "-";
  record.allTablesNames = "-";
  var hasReservedTables = false;
  var hasJoinedTables = false;
  var joinedTables = [];
  if (
    typeof record.reservationtype_id !== "undefined" &&
    record.reservationtype_id == 1
  ) {
    if (
      typeof record.reservedtables !== "undefined" &&
      record.reservedtables.length > 0
    ) {
      hasReservedTables = true;
    }
    if (
      typeof record.reservedtables !== "undefined" &&
      record.reservedtables.length >= 2
    ) {
      hasJoinedTables = true;
    }
  }
  if (hasReservedTables) {
    record.tableName = record.reservedtables[0].table.name;
  }
  if (hasJoinedTables) {
    joinedTables = record.reservedtables.map(function(table) {
      return table.table.name;
    });
    record.joinedTablesNames = joinedTables.join(",");
    record.allTablesNames = joinedTables.join(",");
  }
}
export function attachMissingRelationships(arrayOfReservations, reservation) {
  for (var i = 0; i < arrayOfReservations.length; i++) {
    if (arrayOfReservations[i].id == reservation.id) {
      if (typeof arrayOfReservations[i].reservedtables !== "undefined") {
        reservation.reservedtables = arrayOfReservations[i].reservedtables;
      }
      if (reservation.customer_id == null) {
        reservation.customer_profile = getUnknownGuestObject();
      } else if (
        typeof arrayOfReservations[i].customer_profile !== "undefined" &&
        typeof arrayOfReservations[i].customer_profile.tags !== "undefined"
      ) {
        reservation.customer_profile.tags =
          arrayOfReservations[i].customer_profile.tags;
      }
    }
  }
}

import { RouteInfo } from './sidebar.metadata';

export const ORDERINGROUTES: RouteInfo[] = [
  {
    path: '/ordering/dashboard', title: 'Dashboard', icon: 'foh-icon foh-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    alias: "customers", path: '/customers/list', title: 'Profiles', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    path: '/ordering/venues/list', title: 'Venues Setup', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    path: '/ordering/menu-setup/list', title: 'Menu Setup', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
];

import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { AlertService } from "./alert.service";
import { Globals } from "@foh/globals";
import { Observable, of } from "rxjs";
import { LookupService } from "./lookup.service";
import { ReservationupdaterService } from "./reservationupdater.service";
import { SmsService } from "./smsservice.service";
import { OperationsupdaterService } from "./operationsupdater.service";
import { CallmanagerService } from "./callmanager.service";
import { NotificationsService } from "./notifications.service";
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: "root"
})
export class SocketsService {
  public socket;
  public socketConnected: boolean;
  public interval = null;
  public eventsBinded : boolean=false;
  constructor(
    private globals: Globals,
    private lookupService: LookupService,
    private authService: AuthService,
    private smsService: SmsService,
    private callmanagerService: CallmanagerService,
    private operationsupdaterService: OperationsupdaterService,
    private notificationsService: NotificationsService,
    private alertService: AlertService,    
    private reservationupdaterService: ReservationupdaterService
  ) {
    this.socketConnected = false;
  }

  connect() {
    var mainObservable = Observable.create(observer => {
      this.connectToNodeServer();
      observer.next(this.socketConnected);
      observer.complete();
    });
    return mainObservable;
  }

  connectToNodeServer() {
    if (this.socket) {
      this.socket.destroy();
      delete this.socket;
      this.socket = null;
    }
    this.socket = io(this.globals.URL_SOCKET, {
      transports: ["websocket"],
      secure: true,
      reconnection: false
    });
    this.socket.on("connect", () => {
      this.socketConnected = true;
      this.registerToChannels();
      this.bindEvents();
      this.reservationupdaterService.forceRefresh();
    });
    this.socket.on("disconnect", () => {
      this.socketConnected = false;
      this.retryConnection();
    });
    this.socket.on("connect_error", () => {
      this.socket.disconnect();
      this.socketConnected = false;
    });
    this.socket.on("connect_timeout", () => {
      this.socketConnected = false;
      this.retryConnection();
    });
    this.socket.on("error", () => {
      this.socketConnected = false;
    });
  }

  registerToChannels() {
    if (!this.socketConnected) {
      return;
    }
    if (this.lookupService.lookupLoaded) {
      this.socket.emit("register-socket", {
        tokens: this.lookupService.getChannels(),
        name: this.authService.user.name,
        mcompany_id: this.authService.user.mcompany_id,
        platform: "Web"
      });
    } else {
      this.lookupService.listen().subscribe(event => {
        if (event == "lookup-loaded") {
          this.socket.emit("register-socket", {
            tokens: this.lookupService.getChannels(),
            name: this.authService.user.name,
            mcompany_id: this.authService.user.mcompany_id,
            platform: "Web"
          });
        }
      });
    }
  }

  retryConnection() {
    this.interval = window.setInterval(() => {
      if (this.socketConnected) {
        clearInterval(this.interval);
        this.interval = null;
        return;
      }
      //this.connect().subscribe();
      this.connectToNodeServer();
    }, 3000);
  }

  bindEvents() {
    if (!this.socketConnected && !this.eventsBinded) {
      return;
    }
    // this.socket.on("new-queue-added", event => {
    //   var queue = JSON.parse(event.queue);
    //   this.reservationupdaterService.addQueue(queue);
    // });
    this.socket.on("new-reservation-added", event => {
      var reservation = JSON.parse(event.reservation);
      this.reservationupdaterService.add(reservation);
    });
    this.socket.on("reservation-updated", event => {
      var reservation = JSON.parse(event.reservation);
      var userId = parseInt(event.userId);
      this.reservationupdaterService.update(reservation,userId);
    });
    this.socket.on("reservation-tables-updated", event => {
      var reservation = JSON.parse(event.reservation);
      var userId = parseInt(event.userId);
      this.reservationupdaterService.updateWithTables(reservation,userId);
    });
    this.socket.on("mass-tables-deallocate", event => {
      var reservationParams = JSON.parse(event.params);
      var userId = parseInt(event.userId);
      this.reservationupdaterService.massDeallocate(reservationParams,userId);
    });
    this.socket.on("attendance-updated", event => {
      var reservation = JSON.parse(event.reservation);
      var list = JSON.parse(event.list);
      var userId = parseInt(event.userId);
      this.reservationupdaterService.updateAttendance(reservation, list,userId);
    });
    this.socket.on("new-request-added", event => {
      var request = JSON.parse(event.request);
      var newCounter = JSON.parse(event.newPendingRequestsCounter);
      this.reservationupdaterService.addRequest(request);
      this.reservationupdaterService.updatePendingRequestsBadge(newCounter);
    });
    this.socket.on("request-approved", event => {
      var reservation = JSON.parse(event.reservation);
      var newCounter = JSON.parse(event.newPendingRequestsCounter);
      this.reservationupdaterService.requestApproved(reservation);
      this.reservationupdaterService.updatePendingRequestsBadge(newCounter);
    });
    this.socket.on("request-rejected", event => {
      var reservation = JSON.parse(event.reservation);
      var newCounter = JSON.parse(event.newPendingRequestsCounter);
      this.reservationupdaterService.requestRejected(reservation);
      this.reservationupdaterService.updatePendingRequestsBadge(newCounter);
    });
    this.socket.on("sms-balance-updated", event => {
      var newBalance = JSON.parse(event.newBalance);
      this.smsService.updateBalance(newBalance);
    });
    this.socket.on("new-call", event => {
      this.callmanagerService.notifyForNewCall({
        customerprofile: JSON.parse(event.customerprofile),
        mobileno: event.mobileno
      });
    });
    this.socket.on("new-task-in-progress", event => {
      var operationId = parseInt(event.operation_id);
      this.operationsupdaterService.operationIsInProgress(operationId);
    });
    this.socket.on("new-task-completed", event => {
      var operationId = parseInt(event.operation_id);
      this.operationsupdaterService.operationIsCompleted(operationId);
    });
    this.socket.on("new-notification-recieved", event => {
      this.notificationsService.newNotificationRecieved( JSON.parse(event.notification));
    });
    this.eventsBinded = true;
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { Globals } from "@foh/globals";
import { Tabletype } from "@foh/mcompany-setup/table-types/tabletype";
import { SmsService } from "./smsservice.service";
import { ReservationupdaterService } from "./reservationupdater.service";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

@Injectable({
  providedIn: "root"
})
export class LookupService {
  mcompany: any = {};
  venues: any = [];
  clientCategories: any = [];
  districts: any = [];
  users: any = [];
  roles: any = [];
  messagesTemplates: any = [];
  emailsTemplates: any = [];
  tableTypes: Tabletype[];
  notifications: any = [];
  lookups: any;
  vars: any;
  lookupLoaded: boolean;
  haveValidLicense: boolean;
  licenseHaveTextWarning: boolean;
  invoiceAlertWarning: boolean;
  licenseHaveAlertWarning: boolean;
  nextBillingDate: any;
  private subject = new Subject<string>();
  constructor(
    private http: HttpClient,
    private reservationupdaterService: ReservationupdaterService,
    private smsService: SmsService,
    private fohmoduleSelectorService: FohmoduleSelectorService,
    private globals: Globals
  ) {
    this.lookupLoaded = false;
  }

  getLookups() {
    var mainObservable = Observable.create(observer => {
      this.http
        .get(this.globals.WEBAPI + this.globals.URL_LOOKUPS_RETRIEVE, {
          params: { module: this.fohmoduleSelectorService.getCurrentModule() }
        })
        .subscribe(response => {
          this.setMCompany(response["mcompany"]);
          this.setVenues(response["venues"]);
          this.setTableTypes(response["tableTypes"]);
          this.setDistricts(response["districts"]);
          this.setClientCategories(response["clientCategories"]);
          this.setUsers(response["users"]);
          this.lookups = response["lookups"];
          this.vars = response["vars"];
          this.smsService.updateBalance(this.vars["smsBalance"]);
          this.reservationupdaterService.updatePendingRequestsBadge(
            this.vars["pendingRequestsCounter"]
          );
          this.roles = response["roles"];
          this.messagesTemplates = response["messagesTemplates"];
          this.emailsTemplates = response["emailsTemplates"];
          this.notifications = response["notifications"];
          this.haveValidLicense = response["haveValidLicense"];
          this.licenseHaveTextWarning = response["licenseHaveTextWarning"];
          this.invoiceAlertWarning = response["invoiceAlertWarning"];
          this.licenseHaveAlertWarning = response["licenseHaveAlertWarning"];
          this.nextBillingDate = response["nextBillingDate"];
          this.lookupLoaded = true;
          this.subject.next("lookup-loaded");
          observer.next(response);
          observer.complete();
        });
    });
    return mainObservable;
  }

  listen() {
    return this.subject.asObservable();
  }

  lookupUpdated(alias, data, action) {
    this.getLookups().subscribe();
  }

  setMCompany(mcompany) {
    this.mcompany = mcompany;
  }
  getMCompany() {
    return this.mcompany;
  }

  microsIntegrated() : boolean{
    return this.lookups.integrations.micros ? true : false
  }

  microsConfiguration(){
    return this.lookups.integrations.micros
  }

  setVenues(venues) {
    this.venues = venues;
  }

  setTableTypes(tableTypes) {
    this.tableTypes = tableTypes;
  }
  setDistricts(districts) {
    this.districts = districts;
  }
  setClientCategories(clientCategories) {
    this.clientCategories = clientCategories;
  }
  setUsers(users) {
    this.users = users;
  }

  getNumberOfVenues() {
    return this.venues.length;
  }

  getNumberOfValidVenues() {
    var number = 0;
    for (var i = 0; i < this.venues.length; i++) {
      if (this.venues[i].branches.length > 0) {
        number++;
      }
    }
    return number;
  }

  getNumberOfBranchesOfVenue(venue) {
    return venue.branches.length;
  }

  getVenues() {
    return this.venues;
  }
  getTableTypes() {
    return this.tableTypes;
  }
  getDistricts() {
    return this.districts;
  }
  getDistrictById(id) {
    for (var i = 0; i < this.districts.length; i++) {
      if (this.districts[i].id == id) {
        return this.districts[i];
      }
    }
    return null;
  }
  getClientCategories() {
    return this.clientCategories;
  }

  getRoles() {
    return this.roles;
  }

  getOptionById(id) {
    const optionObject = this.lookups['reservationCancellationOptions'].find(option => option.id === id);
    return optionObject ? optionObject.option : "Option not found.";
  }

  getVenueById(venueId) {
    var found: any = false;
    for (var i = 0; i < this.venues.length; i++) {
      if (this.venues[i].id == venueId) {
        found = {};
        found = this.venues[i];
      }
    }
    return found;
  }

  getBranchWithVenueById(branchId) {
    var found: any = false;
    for (var i = 0; i < this.venues.length; i++) {
      for (var j = 0; j < this.venues[i].branches.length; j++) {
        if (this.venues[i].branches[j].id == branchId) {
          found = {};
          found = this.venues[i].branches[j];
          found.venue = {
            id: this.venues[i].id,
            name: this.venues[i].name,
            mcompany_id: this.venues[i].mcompany_id,
            logo: this.venues[i].logo
          };
        }
      }
    }
    return found;
  }

  getAllBranches() {
    var branches = [];
    for (var i = 0; i < this.venues.length; i++) {
      for (var j = 0; j < this.venues[i].branches.length; j++) {
        let tempBranch: any = {};
        tempBranch = this.venues[i].branches[j];
        tempBranch.venue = {
          id: this.venues[i].id,
          name: this.venues[i].name,
          mcompany_id: this.venues[i].mcompany_id,
          logo: this.venues[i].logo
        };

        branches.push(tempBranch);
      }
    }
    return branches;
  }
  getAllWeddingBranches() {
    var branches = [];
    for (var i = 0; i < this.venues.length; i++) {
      if (this.venues[i].wedding != 1) {
        continue;
      }
      for (var j = 0; j < this.venues[i].branches.length; j++) {
        if (this.venues[i].branches[j].iswedding != 1) {
          continue;
        }
        let tempBranch: any = {};
        tempBranch = this.venues[i].branches[j];
        tempBranch.venue = {
          id: this.venues[i].id,
          name: this.venues[i].name,
          mcompany_id: this.venues[i].mcompany_id,
          logo: this.venues[i].logo
        };

        branches.push(tempBranch);
      }
    }
    return branches;
  }

  getAllBranchesOfVenue(venueId) {
    var found: any = false;
    for (var i = 0; i < this.venues.length; i++) {
      if (this.venues[i].id == venueId) {
        found = this.venues[i].branches;
        break;
      }
    }
    return found;
  }

  getTableTypeById(tableTypeId) {
    var found: any = false;
    for (var i = 0; i < this.tableTypes.length; i++) {
      if (this.tableTypes[i].id == tableTypeId) {
        found = {};
        found = this.tableTypes[i];
        break;
      }
    }
    return found;
  }

  getClientCategoryById(clientCategoryId) {
    var found: any = false;
    for (var i = 0; i < this.clientCategories.length; i++) {
      if (this.clientCategories[i].id == clientCategoryId) {
        found = {};
        found = this.clientCategories[i];
        break;
      }
    }
    return found;
  }
  getUserById(userId) {
    var found: any = false;
    for (var i = 0; i < this.users.length; i++) {
      if (this.users[i].id == userId) {
        found = {};
        found = this.users[i];
        break;
      }
    }
    return found;
  }

  getSlots(branchId) {
    var branch = this.getBranchWithVenueById(branchId);
    return branch.slots;
  }
  getOpenhours(branchId) {
    var branch = this.getBranchWithVenueById(branchId);
    return branch.openhours;
  }

  getAllSlots() {
    var arrayOfSlots = [];
    var allBranches = this.getAllBranches();
    for (var i = 0; i < allBranches.length; i++) {
      var slots = allBranches[i].slots;
      for (var j = 0; j < slots.length; j++) {
        var slot = slots[j];
        slot.branch = allBranches[i];
        arrayOfSlots.push(slot);
      }
    }

    return arrayOfSlots;
  }

  getSlotById(id, branchId) {
    var found: any = false;
    if (id === null) {
      return found;
    }
    var branch = this.getBranchWithVenueById(branchId);
    var slots = branch.slots;
    if (typeof branch.slots === "undefined") {
      return found;
    }
    for (var i = 0; i < slots.length; i++) {
      if (slots[i].id == id) {
        found = {};
        found = slots[i];
        break;
      }
    }
    return found;
  }

  getSlotByIdOnly(id) {
    var arrayOfSlots = this.getAllSlots();
    for (var i = 0; i < arrayOfSlots.length; i++) {
      if (arrayOfSlots[i].id == id) {
        return arrayOfSlots[i];
      }
    }
    return false;
  }

  /*Do use the word messageTemplate any more, replace with smsTemplate*/
  getMessageTemplates() {
    return this.getSmsTemplates();
  }
  getSmsTemplates() {
    return this.messagesTemplates;
  }
  getEmailTemplates() {
    return this.emailsTemplates;
  }

  getRezMessageType() {
    var allowedIds = this.lookups["messageTypesIdsForRez"];
    var messageTypes = this.getAllMessageType();
    return this._fillInMessageTemplates(messageTypes, allowedIds);
  }
  getPassMessageType() {
    var allowedIds = this.lookups["messageTypesIdsForPass"];    
    var messageTypes = this.getAllMessageType();
    return this._fillInMessageTemplates(messageTypes, allowedIds);
  }
  getWeddingMessageType() {
    var allowedIds = this.lookups["messageTypesIdsForWedding"];
    var messageTypes = this.getAllMessageType();
    return this._fillInMessageTemplates(messageTypes, allowedIds);
  }
  _fillInMessageTemplates(messages, allowedIds) {
    var filteredMessages = [];
    for (var i = 0; i < allowedIds.length; i++) {
      filteredMessages.push({
        id: allowedIds[i],
        text: messages[allowedIds[i]]
      });
      //filteredMessages[allowedIds[i]] = messages[allowedIds[i]];
    }
    return filteredMessages;
  }
  getAllMessageType() {
    return this.lookups["messageTypes"];
  }

  getReservationCancellationOptions(){
    return this.lookups['reservationCancellationOptions']
  }

  getMessageType(code) {
    return this.lookups["messageTypes"][code];
  }
  getAllRejectionList() {
    return this.lookups["rejectionList"];
  }
  getRezRejectionlist() {
    return this.lookups["rezRejectionList"];
  }
  getPassRejectionlist() {
    return this.lookups["passRejectionList"];
  }
  getRejectionItem(id) {
    return this.lookups["rejectionList"][id];
  }
  getMessagePlatform(code) {
    return this.lookups["messagePlatforms"][code];
  }
  getMessageStatus(code) {
    return this.lookups["messageStatus"][code];
  }
  getReservationStatus(code) {
    return this.lookups["reservationStatus"][code];
  }
  getOperationType(code) {
    return this.lookups["operationTypes"][code];
  }
  getOperationStatus(code) {
    return this.lookups["operationStatus"][code];
  }
  getGlobalConfig() {
    return this.lookups["globalconfigs"];
  }
  updateGlobalConfig(configs) {
    this.lookups["globalconfigs"] = configs;
  }

  getChannels() {
    return this.lookups["channels"];
  }

  getQrCodeInvMode() {
    var allBranches = this.getAllBranches();
    for (var i = 0; i < allBranches.length; i++) {
      if (allBranches[i].qrgate_invitationdriver != null && allBranches[i].qrgate_invitationdriver != "") {
        return true;
      }
    }
    return false;
  }
  getQrCodeInvModePerBranchId(id) {
    var allBranches = this.getAllBranches();
    for (var i = 0; i < allBranches.length; i++) {
      if (allBranches[i].id == id && allBranches[i].qrgate_invitationdriver != null && allBranches[i].qrgate_invitationdriver != "") {
        return true;
      }
    }
    return false;
  }

  getWeddingStatus(id) {
    return this.lookups.weddingStatus[id];
  }
  getWeddingStatusCss(id) {
    var css = "";
    switch (id) {
      case "1":case 1:
        css = "bkcolor-foh-yellow";
        break;
      case "2":case 2:
        css = "bkcolor-foh-yellow";
        break;
      case "3":case 3:
        css = "bkcolor-foh-green";
        break;
      case "4":case 4:
        css = "bkcolor-foh-red2";
        break;
      case "5":case 5:
        css = "bkcolor-foh-red2";
        break;
      case "6":case 6:
        css = "bkcolor-foh-gray";
        break;
      case "7":case 7:
        css = "bkcolor-foh-darkgray";
        break;
      case "8":case 8:
        css = "bkcolor-foh-green";
        break;
    }
    return css;
  }
}
import { Routes } from '@angular/router';
import { environment } from "environments/environment";

// Route for content layout with sidebar, navbar, and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: './shared/modules/changelog/changelog.module#ChangeLogModule'
  },
  {
    path: 'users-setup/users',
    loadChildren: './users-setup/users/users.module#UsersModule'
  },
  {
    path: 'users-setup/superadmins',
    loadChildren: './users-setup/superadmins/superadmins.module#SuperadminsModule'
  },
  {
    path: 'users-setup/roles',
    loadChildren: './users-setup/roles/roles.module#RolesModule'
  },
  {
    path: 'mcompany-setup',
    loadChildren: './mcompany-setup/mcompany-setup.module#McompanySetupModule'
  },
  {
    path: 'mcompany-setup/tags',
    loadChildren: './mcompany-setup/tags/tags.module#TagsModule'
  },
  {
    path: 'sms-management',
    loadChildren: './sms-management/sms-management.module#SmsManagementModule'
  },
  {
    path: 'customers',
    loadChildren: './customers/customers.module#CustomersModule'
  },
  {
    path: 'reservations',
    loadChildren: './reservations/reservations.module#ReservationsModule'
  },
  {
    path: 'table-setup',
    loadChildren: './table-setup/table-setup.module#TableSetupModule'
  },
  {
    path: 'operations',
    loadChildren: './operations/operations.module#OperationsModule'
  },
  {
    path: 'blackList',
    loadChildren: './operations/blacklist/blacklist.module#BlacklistModule'
  },
  {
    path: 'reports',
    loadChildren: './reports/reports.module#ReportsModule'
  },
  {
    path: 'subscriptions',
    loadChildren: './subscriptions/subscriptions.module#SubscriptionsModule'
  },
  {
    path: 'rez',
    loadChildren: './rez/rez.module#RezModule'
  },
  {
    path: 'pass',
    loadChildren: './pass/pass.module#PassModule'
  },
  {
    path: 'queue',
    loadChildren: './queue/queue.module#QueueModule'
  },
  {
    path: 'ordering',
    loadChildren: './ordering/ordering.module#OrderingModule'
  },
  {
    path: 'campaign',
    loadChildren: './campaign/campaign.module#CampaignModule'
  },
  {
    path: 'integrations',
    loadChildren: './integrations/integrations.module#IntegrationsModule'
  },
  {
    path: 'super-admin-board',
    loadChildren: './super-admin-board/super-admin-board.module#SuperAdminBoardModule'
  },
  {
    path: environment.KEYWORD_CITADEL,
    loadChildren: './citadel/citadel.module#CitadelModule'
  },
];

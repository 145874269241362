import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "@foh/globals";
import { Subject } from 'rxjs';
import { LookupService } from './lookup.service';

export interface NotificationEvent {
    data? : any;
    type : string;
}

@Injectable({
  providedIn: "root"
})
export class NotificationsService {
  notifications: any = [];
  numberOfUnread: number = 0;
  private subject = new Subject<NotificationEvent>();
  constructor(private http: HttpClient, private globals: Globals,private lookupService:LookupService)
  {
    this.waitForLookupsLoad();
  }

  listen(){
    return this.subject.asObservable();
  }

  waitForLookupsLoad(){
    if (this.lookupService.lookupLoaded) {
      this.init();
    }
    else{
      this.lookupService.listen().subscribe(event => {
        if (event == "lookup-loaded") {
            this.init();
        }
      });
    }
  }

  init() {
    this.notifications = this._formatNotifications(this.lookupService.notifications);
    this.calcaulateUnread();
  }

  getList() {
    return this.notifications;
  }

  calcaulateUnread() {
    var counter = 0;
    for (var i = 0; i < this.notifications.length; i++) {
      if (
        this.notifications[i].isread == null ||
        this.notifications[i].isread == 0
      ) {
        counter++;
      }
    }
    this.numberOfUnread =  counter;
    this.subject.next({type:"unreadcounter-updated" , data: { numberOfUnread : this.numberOfUnread  } });
  }

  newNotificationRecieved(notification){
    var formattedNotification = this._formatSingleNotification(notification);
    if(formattedNotification != null){
      this.addNotification(formattedNotification);
    }
  }

  addNotification(notification)
  {
    this.notifications.splice(0, 0, notification);
    this.calcaulateUnread();
    this.subject.next({type:"notifcation-added" , data: { numberOfUnread : this.numberOfUnread  } });
  }

  notificationsIsOpen() {
    var idsOfUnRead = this._getIdsOfUnreadNotifications();

    this.http
      .post(this.globals.WEBAPI + this.globals.URL_NOTIFICATIONS_MARKASREAD, {
        ids: idsOfUnRead
      })
      .subscribe(response => {
        this._markIdsAsRead(idsOfUnRead);
      });
  }

  _formatNotifications(notifications){
    var list = [];
    for(var i=0 ;i< notifications.length ; i++ ){
      var notification = this._formatSingleNotification(notifications[i]);
        if(notification == null)
        {
          continue;
        }
      list.push( notification   );
    }
    return list;
  }

  _formatSingleNotification(notification){
    var formattedNotification = null;
    switch(notification.type){
      case 1:
        formattedNotification = this._formatUnClosedNotification(notification);
      break;
      case 999:
        formattedNotification = this._formatCustomNotification(notification);
      break;
    }
    return formattedNotification;
  }

  _formatCustomNotification(notification){
    notification.title = notification.data.title;
    notification.desc = notification.data.desc;
    notification.haveUrl = false;
    return notification;
  }

  _formatUnClosedNotification(notification){
    notification.title = "You have Open Cheques";
    notification.desc = "The following branches have open cheques: ";
    var alerts = [];
    var numberOfVenues = this.lookupService.getNumberOfVenues();
    for(var i=0;i < notification.data.length; i++ ){
      var branch = this.lookupService.getBranchWithVenueById( notification.data[i]['branch_id'] );
      var branchName = "";
        if(numberOfVenues > 1){
          branchName = branch.venue.name+" - " + branch.name;
        }else{
          branchName =  branch.name;
        }
        alerts.push(branchName + " have " +  notification.data[i]['count'] + " unclosed cheques" );
    }
    notification.desc+=alerts.join(",");
    notification.haveUrl = true;
    notification.urllink = "/reports/opened-cheques";
    notification.urlText = "Click here to check them";

    return notification;
  }

  _getIdsOfUnreadNotifications() {
    var ids = [];
    for (var i = 0; i < this.notifications.length; i++) {
      if (
        this.notifications[i].isread == null ||
        this.notifications[i].isread == 0
      ) {
        ids.push(this.notifications[i].id);
      }
    }
    return ids;
  }

  _markIdsAsRead(ids) {
    for (var i = 0; i < ids.length; i++) {
      for (var j = 0; j < this.notifications.length; j++) {
        if (ids[i] == this.notifications[j].id) {
          this.notifications[j].isread = 1;
        }
      }
    }
    this.calcaulateUnread();
  }
}

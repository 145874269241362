import { count } from "sms-length";

export function formatDateCustomObjectToSql(date) {
  var leadingDayZero = "";
  var leadingMonthZero = "";
  if (date.month <= 9) {
    leadingMonthZero = "0";
  }
  if (date.day <= 9) {
    leadingDayZero = "0";
  }
  return (
    date.year +
    "-" +
    leadingMonthZero +
    date.month +
    "-" +
    leadingDayZero +
    date.day
  );
}
export function formatDateCustomObjectToDate(date) {
  var convertedDate = new Date();
  convertedDate.setFullYear(date.year);
  convertedDate.setMonth(date.month - 1);
  convertedDate.setDate(date.day);
  return convertedDate;
}

export function compareCustomDateWithSql(customDate, sqlDate) {
  var newCustomDate = formatDateSqlToCustomObject(sqlDate);
  return (
    newCustomDate.year == customDate.year &&
    newCustomDate.month == customDate.month &&
    newCustomDate.day == customDate.day
  );
}
export function compareCustomDateForBirthday(date1, date2) {
  return date1.month == date2.month && date1.day == date2.day;
}

export function formatDateSqlToCustomObject(date) {
  var array = date.split("-");
  return {
    year: parseInt(array[0]),
    month: parseInt(array[1]),
    day: parseInt(array[2])
  };
}
export function formatDateSqlToDateObject(date) {
  // Split the MySQL date string by the '-' character
  const dateParts = date.split('-');

  // Ensure that the MySQL date string has three parts (year, month, day)
  if (dateParts.length !== 3) {
    throw new Error('Invalid MySQL date format');
  }

  // Extract year, month, and day from the split parts
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Months are 0-based in JavaScript (0 = January)
  const day = parseInt(dateParts[2], 10);

  // Create a JavaScript Date object with the extracted components
  const jsDate = new Date(year, month, day);

  // Check if the resulting Date object is valid
  if (isNaN(jsDate.getTime())) {
    throw new Error('Invalid date');
  }

  return jsDate;
}
export function formatDateTimeSqlToDateObject(dateTime) {
  var dateTime = dateTime.split(/[- :]/);
  if (dateTime.length == 5) {
    //Handle The YYYY-MM-DD HH:MM case . (with no seconds)
    dateTime.push("00");
  }
  return new Date(
    dateTime[0],
    dateTime[1] - 1,
    dateTime[2],
    dateTime[3],
    dateTime[4],
    dateTime[5]
  );
}
export function formatDateTimeSqlToCustomObject(dateTime) {
  var dateTime = dateTime.split(/[- :]/);
  if (dateTime.length == 5) {
    //Handle The YYYY-MM-DD HH:MM case . (with no seconds)
    dateTime.push("00");
  }
  return {
    year: parseInt(dateTime[0]),
    month: parseInt(dateTime[1]),
    day: parseInt(dateTime[2]),
    hours: parseInt(dateTime[3]),
    minutes: parseInt(dateTime[4]),
    seconds: parseInt(dateTime[5])
  };
}
export function formatDateTimeSqlToTimeString(dateTime) {
  var dateTime = dateTime.split(/[- :]/);
  if (dateTime.length == 5) {
    //Handle The YYYY-MM-DD HH:MM case . (with no seconds)
    dateTime.push("00");
  }
  return dateTime[3] + ":" + dateTime[4] + ":" + dateTime[5];
}
export function formatDateObjectToSql(date) {
  if (date === null) {
    return "";
  }
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var dayOfMonth = ("0" + date.getDate()).slice(-2);
  return date.getFullYear() + "-" + month + "-" + dayOfMonth;
}
export function formatTimeStringToLabel(time) {
  var dummyDate = "2018-08-20 " + time;
  var date = new Date(dummyDate);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
  //return date.getHours()":"+ date.getMinutes()+" "+;
}

export function getTableShapesList() {
  var shapes = [
    { name: "Square", shape: "foh-table-square" },
    { name: "Circle", shape: "foh-table-circle" },
    { name: "Rectangle", shape: "foh-table-rectangle" }
  ];
  return shapes;
}

export function arrivalTimes(date, timeFrom, timeTo, datePipe, minutesToAdd) {
  var availableTimes = [];
  var timeStart = new Date(
    date.month + "/" + date.day + "/" + date.year + " " + timeFrom
  );
  var timeEnd = new Date(
    date.month + "/" + date.day + "/" + date.year + " " + timeTo
  );
  if (timeEnd < timeStart) {
    timeEnd.setDate(timeEnd.getDate() + 1);
  }
  var currentTime = timeStart;
  while (currentTime < timeEnd) {
    //var currentTimeString = ('0' + currentTime.getHours()).slice(-2) + ":" + ('0' + currentTime.getMinutes()).slice(-2);
    var currentTimeString = formatDateObjectToTime12Format(
      currentTime,
      datePipe
    );
    var timeSlot: any = {
      formatted: currentTimeString,
      date: currentTime
    };
    var newTime = new Date(currentTime.getTime() + minutesToAdd * 60000);
    currentTime = new Date(newTime);
    availableTimes.push(timeSlot);
  }
  return availableTimes;
}
export function formatArrivalTimeForDb(date, datePipe) {
  return datePipe.transform(date, "HH:mm");
}
export function formatArrivalTimeForReservationCard(date, datePipe) {
  if (date == null) {
    return { time: "Not Set", ampm: "" };
  }
  var replacedDate = date;
  if (typeof date === "string") {
    replacedDate = date.replace(" ", "T");
  }
  return {
    time: datePipe.transform(replacedDate, "hh:mm"),
    ampm: datePipe.transform(replacedDate, "aaa")
  };
}

export function generateSelectedArrivalTime(date, datePipe) {
  var currentTime = new Date(date);
  var currentTimeString = formatDateObjectToTime12Format(currentTime, datePipe);
  var timeSlot: any = {
    formatted: currentTimeString,
    date: currentTime
  };
  return timeSlot;
}

export function getTimeToFromArrivalTimeAndDuration(
  date,
  arrivalTime,
  durationInMinutes,
  datePipe
) {
  var timeFrom = new Date(
    date.month + "/" + date.day + "/" + date.year + " " + arrivalTime
  );
  var timeTo = new Date(timeFrom.getTime() + durationInMinutes * 60000);
  return formatArrivalTimeForDb(timeTo, datePipe);
}

export function detectTimeBasedMode(reqDate, branch, isOnlineMode) {
  var haveAValidOpenHourRule = false;
  var haveAValidSlotRule = false;
  var date = createNewDateFromObject(reqDate);
  var dayNumber = date.getDay();
  var mappedDay = mapDayNumber(dayNumber);
  var arrayOfOpenHoursRules = filterOpenhoursPerDate(
    reqDate,
    branch["openhours"],
    isOnlineMode
  );
  var arrayOfValidSlots = filterSlotsPerDate(
    reqDate,
    branch["slots"],
    isOnlineMode
  );
  if (arrayOfOpenHoursRules.length > 0) {
    haveAValidOpenHourRule = true;
  }
  if (arrayOfValidSlots.length > 0) {
    haveAValidSlotRule = true;
  }
  if (haveAValidSlotRule) {
    return {
      mode: "slotbased",
      rules: arrayOfValidSlots
    };
  }
  if (haveAValidOpenHourRule) {
    return {
      mode: "hourbased",
      rules: arrayOfOpenHoursRules
    };
  }
  return {
    mode: false,
    rules: []
  };
}

export function filterOpenhoursPerDate(reqDate, openhours, isOnlineMode) {
  var filteredRecords = [];
  var date = createNewDateFromObject(reqDate);
  var dayNumber = date.getDay();
  var mappedDay = mapDayNumber(dayNumber);
  for (var i = 0; i < openhours.length; i++) {
    if (isOnlineMode && openhours[i].hideonline == true) {
      continue;
    }
    if (isOnlineMode) {
      //Can Book the slot today?
      var subtractedDate = subtractGivenDate(date, openhours[i].allowdaysprior);
      var todayDate = new Date();
      if (todayDate < subtractedDate) {
        continue;
      }
    }
    //Filter By Day

    if (
      openhours[i][mappedDay] == 1 &&
      slotNotExcluded(date, openhours[i].excludeddates, isOnlineMode)
    ) {
      filteredRecords.push(openhours[i]);
    }
  }
  return filteredRecords;
}
export function filterSlotsPerDate(reqDate, slots, isOnlineMode) {
  var filteredSlots = [];
  var date = createNewDateFromObject(reqDate);
  var dayNumber = date.getDay();
  var mappedDay = mapDayNumber(dayNumber);
  for (var i = 0; i < slots.length; i++) {
    if (isOnlineMode && slots[i].hideonline == true) {
      continue;
    }
    if (isOnlineMode) {
      //Can Book the slot today?
      var subtractedDate = subtractGivenDate(date, slots[i].allowdaysprior);
      var todayDate = new Date();
      if (todayDate < subtractedDate) {
        continue;
      }
      if (
        typeof slots[i].dayslockfromtoday !== "undefined" &&
        slots[i].dayslockfromtoday != null &&
        slots[i].dayslockfromtoday != ""
      ) {

        var lockedDate = subtractGivenDate(date, slots[i].dayslockfromtoday - 1);
        if (todayDate > lockedDate) {
          continue;
        }
      }

    }
    //Filter By Day
    if (slots[i].type == 1) {
      if (
        slots[i][mappedDay] == 1 &&
        slotNotExcluded(date, slots[i].excludeddates, isOnlineMode)
      ) {
        filteredSlots.push(slots[i]);
      }
    }
    //Filter By Date Range
    if (slots[i].type == 2) {
      //var fromDate = new Date(slots[i].datefrom+ " 00:00:00");
      var fromDate = createNewDateFromString(slots[i].datefrom);
      //var toDate = new Date(slots[i].dateto+ " 00:00:00");
      var toDate = createNewDateFromString(slots[i].dateto);
      if (date >= fromDate && date <= toDate) {
        filteredSlots.push(slots[i]);
      }
    }
  }
  return filteredSlots;
}
export function filterEventsPerDate(reqDate, events) {
  var filteredEvents = [];
  var date = createNewDateFromObject(reqDate);
  for (var i = 0; i < events.length; i++) {
    //Filter By Date Range
    var fromDate = createNewDateFromString(events[i].startdate);
    var toDate = createNewDateFromString(events[i].startdate);
    if (events[i].enddate != null) {
      toDate = createNewDateFromString(events[i].enddate);
    }
    if (date >= fromDate && date <= toDate) {
      filteredEvents.push(events[i]);
    }
  }
  return filteredEvents;
}

function slotNotExcluded(date, excludeddates, isOnlineMode) {
  var state: boolean = true;
  for (var i = 0; i < excludeddates.length; i++) {
    var fromDate = createNewDateFromString(excludeddates[i].from);
    var toDate = createNewDateFromString(excludeddates[i].to);
    if (date >= fromDate && date <= toDate) {
      var hideFromOnlineOnly = excludeddates[i].hideonlineonly;

      if (hideFromOnlineOnly && isOnlineMode) {
        state = false;
        break;
      } else if (hideFromOnlineOnly && !isOnlineMode) {
        state = true;
        break;
      } else {
        state = false;
        break;
      }
    }
  }
  return state;
}

export function createNewDateFromObject(date) {
  return new Date(date.year, date.month - 1, date.day, 0, 0, 0);
}
export function createNewDateFromString(date) {
  var arrayOfDate = date.split("-");
  return new Date(
    parseInt(arrayOfDate[0]),
    parseInt(arrayOfDate[1]) - 1,
    parseInt(arrayOfDate[2]),
    0,
    0,
    0
  );
}
export function createNewDateDateFromString(date) {
  var parts = date.split(" ");
  var arrayOfDate = parts[0].split("-");
  var arrayOfTime = parts[1].split(":");
  return new Date(
    parseInt(arrayOfDate[0]),
    parseInt(arrayOfDate[1]) - 1,
    parseInt(arrayOfDate[2]),
    parseInt(arrayOfTime[0]),
    parseInt(arrayOfTime[1]),
    parseInt(arrayOfTime[2])
  );
}
export function createNewDateTimeFromString(date) {
  if (typeof date === "undefined" || date === null) {
    return null;
  }
  var arrayOfDateTime = null;
  if (date.includes("T")) {
    arrayOfDateTime = date.split("T");
  } else {
    arrayOfDateTime = date.split(" ");
  }
  var arrayOfDate = arrayOfDateTime[0].split("-");
  var arrayOfTime = arrayOfDateTime[1].split(":");
  if (arrayOfTime.length <= 2) {
    arrayOfTime[2] = "00";
  }
  return new Date(
    parseInt(arrayOfDate[0]),
    parseInt(arrayOfDate[1]) - 1,
    parseInt(arrayOfDate[2]),
    parseInt(arrayOfTime[0]),
    parseInt(arrayOfTime[1]),
    parseInt(arrayOfTime[2])
  );
}

export function getDurationAccordingToTime(dateInString) {
  let dateObject: any = createNewDateTimeFromString(dateInString);
  let dateRightNow: any = new Date();
  var hours = Math.abs(dateObject - dateRightNow) / 36e5;
  return hours;
}

function mapDayNumber(number) {
  var day = "";
  switch (number) {
    case 0:
      day = "sunday";
      break;
    case 1:
      day = "monday";
      break;
    case 2:
      day = "tuesday";
      break;
    case 3:
      day = "wednesday";
      break;
    case 4:
      day = "thursday";
      break;
    case 5:
      day = "friday";
      break;
    case 6:
      day = "saturday";
      break;
  }
  return day;
}
export function getUserInfo(userId, lookupService) {
  var user = lookupService.getUserById(userId);
  if (user == false) {
    return { id: -1, name: "-Not Found-" };
  }
  return user;
}
export function getCreatedInfoInArray(lookupService, reservation) {
  var createdBy = lookupService.getUserById(reservation.created_by);
  var createdByName = "-";
  var confirmedByName = "-";
  if (createdBy == false) {
    createdByName = " - Not Found - ";
  } else {
    createdByName = createdBy.name;
  }

  if (reservation.confirmed_by != null) {
    var confirmedBy = lookupService.getUserById(reservation.confirmed_by);
    if (confirmedBy == false) {
      confirmedByName = " - Not Found - ";
    } else {
      confirmedByName = confirmedBy.name;
    }
  }
  return { createdBy: createdByName, confirmedBy: confirmedByName };
}
export function getCreatedInfo(lookupService, reservation) {
  var text = "Created By: ";
  var creationInfoArray = getCreatedInfoInArray(lookupService, reservation);
  var createdBy = lookupService.getUserById(reservation.created_by);
  text += creationInfoArray.createdBy;

  if (reservation.confirmed_by != null) {
    text += ", Confirmed By:";
    text += creationInfoArray.confirmedBy;
  }
  return text;
}
export function getClosedInfo(lookupService, reservation) {
  var text = "Closed By: ";
  var createdBy = lookupService.getUserById(reservation.closed_by);
  if (createdBy == false) {
    text += " - Not Found - ";
  } else {
    text += createdBy.name;
  }

  if (reservation.final_amount != null && reservation.final_amount != "") {
    text += ", Closing Amount:";
    text += " " + reservation.final_amount + " EGP";
  }
  if (reservation.rate != null) {
    text += ", Rate:";
    text += " " + reservation.rate;
  }
  if (reservation.post_comments != null && reservation.post_comments != "") {
    text += ", Closing Comments:";
    text += " " + reservation.post_comments;
  }
  return text;
}

export function getClientCategory(lookupService, customer_profile) {
  if (customer_profile == null) {
    return null;
  }
  if (customer_profile.clientcat_id == null) {
    return null;
  }
  var clientCat = lookupService.getClientCategoryById(
    customer_profile.clientcat_id
  );
  if (clientCat == false) {
    return null;
  }
  return clientCat;
}

export function isClientCategoryIsRequiredWhenClosing(
  clientcat_id,
  newFlag,
  customerProfile,
  lookupService,
  clientCategories
) {
  return isClientCategoryIsRequiredWhenAction(
    "mandtorycategoryonclose",
    clientcat_id,
    newFlag,
    customerProfile,
    lookupService,
    clientCategories
  );
}

export function isClientCategoryIsRequiredWhenAttendance(
  clientcat_id,
  newFlag,
  customerProfile,
  lookupService,
  clientCategories
) {
  if (customerProfile.main_profile.id == -1) {
    return false;
  }
  return isClientCategoryIsRequiredWhenAction(
    "mandtorycategoryonattendance",
    clientcat_id,
    newFlag,
    customerProfile,
    lookupService,
    clientCategories
  );
}
function isClientCategoryIsRequiredWhenAction(
  action,
  clientcat_id,
  newFlag,
  customerProfile,
  lookupService,
  clientCategories
) {
  if (!lookupService.getGlobalConfig()[action]) {
    //Not Mandatory, no need to keep checking for nothing
    return false;
  }
  if (clientCategories.length <= 0) {
    //There are no client cats, proceed so it won't be an deadend
    return false;
  }
  if (
    newFlag &&
    clientcat_id == "" &&
    customerProfile.clientcat_id != null &&
    customerProfile.clientcat_id != ""
  ) {
    //Ignore If user change the choice to unchanged while there is an active category
    return false;
  }
  if (clientcat_id == "" || clientcat_id == null || clientcat_id == false) {
    return true;
  }

  return false;
}

export function filterMessageTemplatesByType(typeId, list) {
  var newList = [];
  for (var i = 0; i < list.length; i++) {
    if (list[i].type == typeId) {
      newList.push(list[i]);
    }
  }
  return newList;
}
export function filterMessageTemplatesByTypeAndPlatform(
  typeId,
  platform,
  list
) {
  var newList = [];
  for (var i = 0; i < list.length; i++) {
    if (list[i].type == typeId && list[i].platform == platform) {
      newList.push(list[i]);
    }
  }
  return newList;
}

export function getArrayOfMonths() {
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return months;
}

export function getArrayOfYears() {
  var years = [];
  var todayDate = new Date();
  var todayYear = todayDate.getFullYear();
  var startYear = 2015;
  for (var i = startYear; i <= todayYear; i++) {
    years.push(i);
  }
  return years;
}

export function calculateNumberOfMessages(text) {
  var response = count(text);
  return response.messages;
}

export function calculateNumberOfMessagesOld(SMSText) {
  if (SMSText.length === 0) {
    return 0;
  }
  var divSMSCounter = 0;
  var a;
  var x;
  var spaceUnicodes = [
    9,
    11,
    13,
    133,
    160,
    5760,
    8192,
    8193,
    8194,
    8195,
    8196,
    8197,
    8198,
    8199,
    8200,
    8201,
    8202,
    8203,
    8204,
    8205,
    8230,
    8232,
    8233,
    8239,
    8287,
    12288,
    6158,
    8288,
    65279
  ];
  var rejSpace = [];
  for (var i = 0; i < spaceUnicodes.length; i++) {
    rejSpace[i] = new RegExp(String.fromCharCode(spaceUnicodes[i]), "g");
  }
  var GSM = /^[\x00-\x7F_€ΦΓΩΣΘ¡¥èéùìòÇØøÅåÆæßÉ¤ÄÖÑÜ§¿äöñüà£]+$/g;
  var newLineIndex = spaceUnicodes.indexOf(9);
  if (SMSText != null) {
    var replacedSMSText = SMSText;
    for (var i = 0; i < rejSpace.length; i++) {
      if (i == newLineIndex) {
        replacedSMSText = replacedSMSText.replace(rejSpace[i], "       ");
      } else {
        replacedSMSText = replacedSMSText.replace(rejSpace[i], " ");
      }
    }
    replacedSMSText = replacedSMSText.trim();
    var area = replacedSMSText.length;
    for (var i = 0, a = replacedSMSText.length; i < a; i++) {
      if (replacedSMSText[i] == "\n") {
        area++;
      }
    }
    if (GSM.test(replacedSMSText)) {
      if (area == 0) {
        x = 0;
      } else if (area <= 160) {
        x = 1;
      } else {
        x = Math.ceil(area / 153);
      }
      divSMSCounter = x;
    } else {
      if (area == 0) {
        x = 0;
      } else if (area <= 70) {
        x = 1;
      } else {
        x = Math.ceil(area / 67);
      }
      divSMSCounter = x;
    }
  }
  return divSMSCounter;
}

export function getUnknownGuestObject() {
  return {
    main_profile: { id: -1, mobileno: "xxxxxxxxxxx" },
    fname: "Walk-in",
    lname: "Guest",
    clientcat_id: null,
    frequent: false,
    spender: false,
    noshow: false,
    id: -2,
    tags: [],
    total_reservations: null,
    total_canceled_visits: null,
    total_noshow: null
  };
}

export function formatActualStats(statsArray) {
  if (typeof statsArray === "undefined" || statsArray == null) {
    return;
  }
  for (var i = 0; i < statsArray.length; i++) {
    if (statsArray[i].actual == null) {
      statsArray[i].actual = "-";
    }
  }
}

export function recheckStyleArray(statsArray) {
  if (typeof statsArray === "undefined" || statsArray == null) {
    return;
  }
  for (var i = 0; i < statsArray.length; i++) {
    statsArray[i].cssstyle = styleStatsCard(
      statsArray[i].actual,
      statsArray[i].total
    );
  }
}

export function styleStatsCard(actual, total) {
  if (actual == null) {
    return "foh-stats-gray";
  }
  var percentage = (actual / total) * 10;
  if (percentage < 4) {
    return "color-foh-headeralt";
  }
  if (percentage >= 4 && percentage < 10) {
    return "foh-stats-yellow";
  }
  if (percentage >= 10) {
    return "foh-stats-red";
  }
  return "foh-stats-gray";
}

export function injectTicketsAdvancedParams(ticketprices) {
  for (var i = 0; i < ticketprices.length; i++) {
    ticketprices[i].customdeadline =
      ticketprices[i].formattedValue.customdeadline;
    ticketprices[i].customenddate =
      ticketprices[i].formattedValue.customenddate;
    ticketprices[i].customstartdate =
      ticketprices[i].formattedValue.customstartdate;
    ticketprices[i].deadline = ticketprices[i].formattedValue.deadline;
    ticketprices[i].from = ticketprices[i].formattedValue.from;
    ticketprices[i].havelimitednumber =
      ticketprices[i].formattedValue.havelimitednumber;
    ticketprices[i].public = ticketprices[i].formattedValue.public;
    ticketprices[i].to = ticketprices[i].formattedValue.to;
    ticketprices[i].ttype = ticketprices[i].formattedValue.ttype;
    ticketprices[i].stock = ticketprices[i].formattedValue.stock;
  }
}

export function filterTicketsForPublic(ticketprices) {
  var i = ticketprices.length;
  var today = new Date();
  //var today = createNewDateFromObject({year:2020,month:1:day:14});
  while (i--) {
    if (!ticketprices[i].public) {
      ticketprices.splice(i, 1);
      continue;
    }
    if (ticketprices[i].customstartdate) {
      var fromDate = createNewDateFromString(ticketprices[i].from);
      if (today < fromDate) {
        ticketprices.splice(i, 1);
        continue;
      }
    }
    if (ticketprices[i].customenddate) {
      var toDate = createNewDateFromString(ticketprices[i].to);
      if (today > toDate) {
        ticketprices.splice(i, 1);
        continue;
      }
    }
  }
}

export function subtractGivenDate(date, numberOfDays) {
  var subtractedDate = new Date(date.valueOf());
  subtractedDate.setDate(subtractedDate.getDate() - numberOfDays);
  return subtractedDate;
}
export function addGivenDate(date, numberOfDays) {
  var addededDate = new Date(date.valueOf());
  addededDate.setDate(addededDate.getDate() + numberOfDays);
  return addededDate;
}

export function createOverrideMessageTemplateDdl(
  defaultTemplateId,
  messageTemplates
) {
  var ddlArray = [];
  ddlArray.push(
    "<select id='currentOverrideTemplateValue' class='form-control'>"
  );
  if (
    defaultTemplateId == "" ||
    defaultTemplateId == null ||
    defaultTemplateId == false
  ) {
    ddlArray.push('<option value="-1" selected>No Default Template</option>');
  }
  for (var i = 0; i < messageTemplates.length; i++) {
    if (messageTemplates[i].id == defaultTemplateId) {
      ddlArray.splice(
        1,
        0,
        '<option selected value="">' +
        messageTemplates[i].name +
        " (Default Template)</option>"
      );
      continue;
    }
    ddlArray.push(
      '<option value="' +
      messageTemplates[i].id +
      '">' +
      messageTemplates[i].name +
      "</option>"
    );
  }
  ddlArray.push("</select>");
  return ddlArray.join("");
}
export function createReasontemplateDdl(reasons) {
  let ddlArray = [];
  ddlArray.push("<select id='createReasontemplateValue' class='form-control'>");
  for (let i = 0; i < reasons.length; i++) {
    ddlArray.push(
      '<option value="' +
      reasons[i].id +
      '">' +
      reasons[i].option +
      "</option>"
    );
  }
  ddlArray.push("</select>");
  return ddlArray.join("");
}
export function createTicketPayerFromTicketGroup(ticketGroup) {
  var ddlArray = [];
  ddlArray.push("<select id='buyerTicketId' class='form-control'>");
  for (var i = 0; i < ticketGroup.tickets.length; i++) {
    ddlArray.push(
      '<option value="' +
      ticketGroup.tickets[i].id +
      '">' +
      ticketGroup.tickets[i].fname +
      " " +
      ticketGroup.tickets[i].lname +
      "</option>"
    );
  }
  ddlArray.push("</select>");
  return ddlArray.join("");
}
export function createPassRejectionList(rejectReasons) {
  var ddlArray = [];
  ddlArray.push("<select id='passRejectReasonId' class='form-control'>");
  for (var i = 0; i < rejectReasons.length; i++) {
    ddlArray.push(
      '<option value="' +
      rejectReasons[i] +
      '">' +
      rejectReasons[i] +
      "</option>"
    );
  }
  ddlArray.push("<option value='-1'>Others</option>");
  ddlArray.push("</select>");
  return ddlArray.join("");
}

export function formatLogTarget(log) {
  var target = "-";
  switch (log.subject_type) {
    case "App\\Reservation":

      if (log.reservation.customer_id == null || log.reservation.customer_id == -1) {
        target = "Walk-in  Guest";
      } else {
        target = log.reservation.customer_profile.fname + " " + log.reservation.customer_profile.lname;
      }
      target =
        "'s reservation, Date: " +
        log.reservation.date +
        ", Slot Name: " +
        log.reservation.slot.name;
      break;
    case "App\\Customerprofile":
    case "App\\Customer":
      target = log.customer_profile.fname + " " + log.customer_profile.lname;
      break;
  }
  return target;
}
export function formatLogTargetId(log) {
  var target: any = "-";
  switch (log.subject_type) {
    case "App\\Reservation":
      target = false;
      break;
    case "App\\Customerprofile":
    case "App\\Customer":
      target = log.customer_profile.customers_id;
      break;
  }
  return target;
}
export function formatLogType(log) {
  var type = "-";
  switch (log.subject_type) {
    case "App\\Reservation":
      type = "Reservation";
      break;
    case "App\\Customerprofile":
    case "App\\Customer":
      type = "Customer Profile";
      break;
  }
  return type;
}

export function formatLogDesc(log, lookupService) {
  var desc = "";
  switch (log.subject_type) {
    case "App\\Reservation":
      desc = formatReservationLogDesc(log, lookupService);
      break;
    case "App\\Customerprofile":
      desc = formatCustomerProfileLogDesc(log, lookupService);
      break;
  }
  return desc;
}
export function formatCustomerProfileLogDesc(log, lookupService) {
  var desc = "";
  if (log.properties.action == "create_customerprofile") {
    return (
      "Customer Profile Created With the following Data:<br />" +
      formatCustomerProfileParams(log, lookupService)
    );
  }
  if (log.properties.action == "update_customerprofile") {
    return (
      "Customer Profile Updated to the following Data:<br />" +
      formatCustomerProfileParams(log, lookupService)
    );
  }
  if (log.properties.action == "change_customer_mobileno") {
    return (
      "Customer Profile Mobile Number changed from to the following Data:<br />" +
      "Old Mobile Number: " +
      log.properties.params.oldMobileNumber +
      "  <br />" +
      "New Mobile Number: " +
      log.properties.params.newMobileNumber +
      "  <br />"
    );
  }
  return desc;
}
export function formatReservationLogDesc(log, lookupService) {
  var desc = "";
  if (log.properties.action == "gate_checked") {
    return "Guest Name: " + log.properties.params.name;
  }
  if (log.properties.action == "allocate_tables") {
    return log.properties.params.fulldesc;
  }
  if (log.properties.action == "status_change") {
    return formatStatus(log);
  }
  if (log.properties.action == "wedding_statuschange") {
    return formatWeddingStatus(log);
  }
  if (log.properties.action == "create_reservation") {
    return (
      "Reservation Created With the following Data:<br />" +
      formatReservationParams(log, lookupService)
    );
  }
  if (log.properties.action == "edit_reservation") {
    return (
      "Reservation Updated to the following Data:<br />" +
      formatReservationParams(log, lookupService)
    );
  }
  if (log.properties.action == "allocate_tables") {
    return desc;
  }
  if (log.properties.action == "add_tickets") {
    return (
      "Tickets for the below names were created:<br />" +
      log.properties.params.names.join(", ")
    );
  }
  if (log.properties.action == "add_guestlists") {
    return (
      "Guests for the below names were created:<br />" +
      log.properties.params.names.join(", ")
    );
  }
  if (log.properties.action == "removed_ticket") {
    return (
      "User removed the tickets for the following: :<br />" +
      log.properties.params.names.join(", ")
    );
  }
  if (log.properties.action == "removed_guests") {
    return (
      "User removed the guests for the following: :<br />" +
      log.properties.params.names.join(", ")
    );
  }
  if (log.properties.action == "update_ticket_cat") {
    desc +=
      "User updated the ticket category  For : <br /> " +
      log.properties.params.name +
      " switched to " +
      log.properties.params.catname;
  }
  if (log.properties.action == "purchased_tickets") {
    var paymentMethod =
      log.properties.params.payment_method == 1 ? "Cash" : "Online payment";
    desc +=
      "Customer " +
      log.properties.params.billing_name +
      " purchased the following tickets via " +
      paymentMethod +
      ": <br /> " +
      formatPurchasedTickets(log);
  }
  if (log.properties.action == "refund_tickets") {
    desc +=
      "User " +
      log.properties.params.billing_name +
      " refunded the following tickets: <br /> " +
      formatPurchasedTickets(log);
  }
  if (log.properties.action == "update_ticket_status") {
    desc +=
      "User updated the ticket status For : <br /> " +
      log.properties.params.name +
      " switched to ";
    if (log.properties.params.status == "1") {
      desc += "Paid";
    }
    if (log.properties.params.status == "0") {
      desc += "Not Paid";
    }
  }
  if (log.properties.action == "manage_list_rejects") {
    return (
      "Rejection Status updated for the below names were created:<br />" +
      formatRejectionListNames(log.properties.params.actions)
    );
  }
  if (log.properties.action == "wedding_addons_update") {
    return (
      "Wedding Addons Updated:<br />" +
      formatWeddingAddonsMetadata(log.properties.params)
    );
  }
  if (log.properties.action == "wedding_timing_update") {
    return (
      "Wedding Timing Updates:<br />" +
      formatWeddingMetadata(log.properties.params)
    );
  }
  return desc;
}

function formatStatus(log) {
  var desc = "Reservation Status Changed To: ";
  var status = parseInt(log.properties.params.status);
  desc += "'" + mapStatusDesc(status) + "'";
  return desc;
}
function formatWeddingStatus(log) {
  var desc = "Booking Status Changed To: ";
  var status = parseInt(log.properties.params.status);
  desc += "'" + mapWeddingStatusDesc(status) + "'";
  return desc;
}

function formatPurchasedTickets(log) {
  var html = "";
  for (var i = 0; i < log.properties.params.tickets.length; i++) {
    html +=
      log.properties.params.tickets[i].fname +
      " " +
      log.properties.params.tickets[i].lname +
      "<br />";
  }
  return html;
}

function formatReservationParams(log, lookupService) {
  var desc = "";
  if (typeof log.properties.params.activity.attributes === "undefined") {
    return desc;
  }
  if (log.properties.params.activity.attributes.slot_id) {
    var slotName = " - ";
    var slot = lookupService.getSlotByIdOnly(
      log.properties.params.activity.attributes.slot_id
    );
    if (slot !== false) {
      slotName = slot.name + ", " + slot.timefrom + " - " + slot.timeto;
    }
    desc += "Slot: " + slotName + " <Br />";
  }
  if (log.properties.params.activity.attributes.date) {
    desc +=
      "Date: " + log.properties.params.activity.attributes.date + " <Br />";
  }
  if (log.properties.params.activity.attributes.requested_pax) {
    desc +=
      "Requested Pax: " +
      log.properties.params.activity.attributes.requested_pax +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.status) {
    desc +=
      "Status: " +
      mapStatusDesc(log.properties.params.activity.attributes.status) +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.arrival_time) {
    desc +=
      "Arrival Time: " +
      log.properties.params.activity.attributes.arrival_time +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.guest_comments) {
    desc +=
      "Customer Notes: " +
      log.properties.params.activity.attributes.guest_comments +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.note) {
    desc +=
      "Internal Notes: " +
      log.properties.params.activity.attributes.note +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.reservationtype_id) {
    desc += "Type: ";
    switch (log.properties.params.activity.attributes.reservationtype_id) {
      case "1":
      case 1:
        desc += "Seated";
        break;
      case "2":
      case 2:
        desc += "Standing";
        break;
      case "3":
      case 3:
        desc += "Waiting list";
        break;
    }
    desc += " <Br />";
  }
  if (log.properties.params.activity.attributes.totalrequireddeposit) {
    desc +=
      "Total Required Deposit: " +
      log.properties.params.activity.attributes.totalrequireddeposit +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.final_amount) {
    desc +=
      "Closing Amount: " +
      log.properties.params.activity.attributes.final_amount +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.rate) {
    desc +=
      "Closing Rate: " +
      log.properties.params.activity.attributes.rate +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.post_comments) {
    desc +=
      "Closing Comments: " +
      log.properties.params.activity.attributes.post_comments +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.actual_pax) {
    desc +=
      "Updated Check in Pax: " +
      log.properties.params.activity.attributes.actual_pax +
      " <Br />";
  }
  if (log.properties.params.activity.attributes.deadline_date) {
    desc +=
      "Deadline Date: " +
      log.properties.params.activity.attributes.deadline_date +
      " <Br />";
  }
  return desc;
}

function formatCustomerProfileParams(log, lookupService) {
  var desc = "";
  if (typeof log.properties.params.activity.attributes === "undefined") {
    return;
  }
  if (
    typeof log.properties.params.activity.attributes.fname !== "undefined" &&
    log.properties.params.activity.attributes.fname
  ) {
    desc +=
      "First Name: " +
      log.properties.params.activity.attributes.fname +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.lname !== "undefined" &&
    log.properties.params.activity.attributes.lname
  ) {
    desc +=
      "Last Name: " +
      log.properties.params.activity.attributes.lname +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.clientcat_id !==
    "undefined" &&
    log.properties.params.activity.attributes.clientcat_id
  ) {
    var clientCatName = "-Deleted-";
    var clientCat = lookupService.getClientCategoryById(
      log.properties.params.activity.attributes.clientcat_id
    );
    if (clientCat !== false) {
      clientCatName = clientCat.name;
    }
    desc += "Client Category: " + clientCatName + " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.position !== "undefined" &&
    log.properties.params.activity.attributes.position
  ) {
    desc +=
      "Position: " +
      log.properties.params.activity.attributes.position +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.company !== "undefined" &&
    log.properties.params.activity.attributes.company
  ) {
    desc +=
      "Company: " +
      log.properties.params.activity.attributes.company +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.email !== "undefined" &&
    log.properties.params.activity.attributes.email
  ) {
    desc +=
      "Email: " + log.properties.params.activity.attributes.email + " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.gender !== "undefined" &&
    log.properties.params.activity.attributes.gender
  ) {
    desc +=
      "Gender: " + log.properties.params.activity.attributes.gender + " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.birthdate !==
    "undefined" &&
    log.properties.params.activity.attributes.birthdate
  ) {
    desc +=
      "Birthdate: " +
      log.properties.params.activity.attributes.birthdate +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.address !== "undefined" &&
    log.properties.params.activity.attributes.address
  ) {
    desc +=
      "Address: " +
      log.properties.params.activity.attributes.address +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.note !== "undefined" &&
    log.properties.params.activity.attributes.note
  ) {
    desc +=
      "Note: " + log.properties.params.activity.attributes.note + " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.fblink !== "undefined" &&
    log.properties.params.activity.attributes.fblink
  ) {
    desc +=
      "Facebook Link: " +
      log.properties.params.activity.attributes.fblink +
      " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.isblacklist !== "undefined"
  ) {
    desc += "Marked As blacklisted: ";
    desc +=
      log.properties.params.activity.attributes.isblacklist == 1 ? "Yes" : "No";
    desc += " <Br />";
  }
  if (
    typeof log.properties.params.activity.attributes.blacklist_reason !==
    "undefined" &&
    log.properties.params.activity.attributes.blacklist_reason
  ) {
    desc +=
      "Blacklist Reason: " +
      log.properties.params.activity.attributes.blacklist_reason +
      " <Br />";
  }
  return desc;
}

function formatWeddingMetadata(params) {
  var desc = "";
  if (typeof params.branchName !== 'undefined' && params.branchName) {
    desc +=
      "Location: " +
      params.branchName +
      " <Br />";
  }
  if (typeof params.date !== 'undefined' && params.date) {
    desc +=
      "Date: " +
      params.date +
      " <Br />";
  }
  if (typeof params.slotName !== 'undefined' && params.slotName) {
    desc +=
      "Timing: " +
      params.slotName +
      " <Br />";
  }
  if (typeof params.requested_pax !== 'undefined' && params.requested_pax) {
    desc +=
      "Pax: " +
      params.requested_pax +
      " <Br />";
  }
  if (typeof params.totalrequireddeposit !== 'undefined' && params.totalrequireddeposit) {
    desc +=
      "Total Amount: " +
      params.totalrequireddeposit +
      " EGP <Br />";
  }
  if (typeof params.note !== 'undefined' && params.note) {
    desc +=
      "Internal Notes: " +
      params.note +
      " <Br />";
  }
  return desc;
}
function formatWeddingAddonsMetadata(params) {
  var desc = "";
  if (typeof params.metadata !== 'undefined' && params.metadata) {
    if (typeof params.metadata.companyName !== 'undefined' && params.metadata.companyName) {
      desc +=
        "Company Name: " +
        params.metadata.companyName +
        " <Br />";
    }
    if (typeof params.metadata.addons !== 'undefined' && params.metadata.addons) {
      for (var i = 0; i < params.metadata.addons.length; i++) {
        switch (params.metadata.addons[i].alias) {
          case 'photoshoot':
            desc +=
              "Photoshoot: During Working Hours: " + params.metadata.addons[i].during + ", After Working Hours: " + params.metadata.addons[i].after + "<Br />";
            break;
          case 'parking':
            desc +=
              "Parking: Inside: " + params.metadata.addons[i].inside_tickets + ", Outside: " + params.metadata.addons[i].outside_tickets + "<Br />";
            break;
          case 'preparation_days':
            desc +=
              "Prep Days: Number Of Days: " + params.metadata.addons[i].number_of_days + "<Br />";
            break;
          case 'transportation':
            desc +=
              "Transportation: Cars: " + params.metadata.addons[i].number_of_cars + ", Buses: " + params.metadata.addons[i].number_of_buses + "<Br />";
            break;

        }
      }
    }
  }
  if (typeof params.totalrequireddeposit !== 'undefined' && params.totalrequireddeposit) {
    desc +=
      "Total Amount: " + params.totalrequireddeposit + " EGP <Br />";
  }
  if (typeof params.note !== 'undefined' && params.note) {
    desc +=
      "Internal Notes: " + params.note + "<Br />";
  }
  return desc;

}

function formatRejectionListNames(actionsString) {
  var actions = JSON.parse(actionsString);
  var str = "";
  for (var i = 0; i < actions.length; i++) {
    str += actions[i].name + ": ";
    str += actions[i].rejected ? "Rejected" : "Accepted";
    str += "<br />";
  }
  return str;
}

//=============Guest List Methods
export function formatTicketCategoryForGuest(
  guest,
  isTicketType,
  ticketPrices
) {
  guest.formattedTicketCategory = "-";
  guest.formattedTicketDeadline = null;
  if (!isTicketType) {
    return guest;
  }
  var ticketCategory = getTicketCategoryById(guest.ticketcat_id, ticketPrices);
  if (ticketCategory != false) {
    guest.formattedTicketCategory = formatTicketCategory(ticketCategory);
    if (ticketCategory.formattedValue.customdeadline) {
      guest.formattedTicketDeadline = ticketCategory.formattedValue.deadline;
    }
  }
  return guest;
}

export function getTicketCategoryById(ticketId, ticketPrices) {
  for (var i = 0; i < ticketPrices.length; i++) {
    if (ticketPrices[i].id == ticketId) {
      return ticketPrices[i];
    }
  }
  return false;
}

export function formatTicketCategory(ticketCategory) {
  return ticketCategory.name + " - " + ticketCategory.price + " EGP";
}

export function formatSlotNameForPublic(selectedSlot, showSlotEndTime) {
  var formattedSlot = "";
  if (selectedSlot.alias == null || selectedSlot.alias == "") {
    formattedSlot = selectedSlot.name;
  } else {
    formattedSlot = selectedSlot.alias;
  }
  formattedSlot +=
    " , " +
    selectedSlot.formattedTimeFrom;
  if (showSlotEndTime) {
    formattedSlot += " - " +
      selectedSlot.formattedTimeTo;
  }

  return formattedSlot;
}

export function getGuestListInText(guestslist) {
  var string = [];
  for (var i = 0; i < guestslist.length; i++) {
    var tempText = "";
    tempText +=
      i +
      1 +
      ". " +
      guestslist[i].fname +
      " " +
      guestslist[i].lname +
      "\n" +
      "Mobile Number: " +
      guestslist[i].mobileno +
      "\n" +
      "Facebook link: " +
      guestslist[i].fblink +
      "\n";
    tempText += "Gender: ";
    tempText += guestslist[i].gender == "m" ? "Male" : "Female";
    tempText += "\n";
    tempText += guestslist[i].isblacklist ? "Marked As Blacklist \n" : "";
    tempText += guestslist[i].rejected ? "Rejected \n" : "";
    string.push(tempText);
  }
  return string.join("............ \n");
}
export function getTicketListInText(guestslist) {
  var string = [];
  for (var i = 0; i < guestslist.length; i++) {
    var tempText = "";
    tempText +=
      i +
      1 +
      ". " +
      guestslist[i].fname +
      " " +
      guestslist[i].lname +
      "\n" +
      "Mobile Number: " +
      guestslist[i].mobileno +
      "\n" +
      "Facebook link: " +
      guestslist[i].fblink +
      "\n";
    tempText += "Gender: ";
    tempText += guestslist[i].gender == "m" ? "Male" : "Female";
    tempText += "\n";
    tempText += guestslist[i].isblacklist ? "Marked As Blacklist \n" : "";
    tempText += guestslist[i].rejected ? "Rejected \n" : "";
    string.push(tempText);
  }
  return string.join("............ \n");
}

export function copyToClipboard(text) {
  let selBox = document.createElement("textarea");

  selBox.style.position = "fixed";
  selBox.style.left = "0";
  selBox.style.top = "0";
  selBox.style.opacity = "0";
  selBox.value = text;

  document.body.appendChild(selBox);
  selBox.focus();
  selBox.select();

  document.execCommand("copy");
  document.body.removeChild(selBox);
}

export function formatBranchTitle(branch) {
  return branch["venue"]["name"] + " - " + branch["name"];
}

export function mapStatus(statusId) {
  var css = "";
  switch (statusId) {
    case 1:
      css = "foh-status-reserved";
      break;
    case 2:
      css = "foh-status-confirmed";
      break;
    case 3:
      css = "foh-status-attended";
      break;
    case 4:
      css = "foh-status-closed";
      break;
    case 5:
      css = "foh-status-canceled";
      break;
    case 6:
      css = "foh-status-requested";
      break;
  }
  return css;
}
export function mapStatusDesc(statusId) {
  var desc = "";
  switch (statusId) {
    case 1:
      desc += "Reserved";
      break;
    case 2:
      desc += "Confirmed";
      break;
    case 3:
      desc += "Attended";
      break;
    case 4:
      desc += "Closed";
      break;
    case 5:
      desc += "Canceled";
      break;
    case 6:
      desc += "Requested";
      break;
  }
  return desc;
}
export function mapWeddingStatusDesc(statusId) {
  var desc = "";
  switch (statusId) {
    case 1:
      desc += "Request Recieved";
      break;
    case 2:
      desc += "Waiting For Auth";
      break;
    case 3:
      desc += "Request Approved";
      break;
    case 4:
      desc += "Ministry Tourism Fees is paid";
      break;
    case 5:
      desc += "Request Confirmed";
      break;
    case 6:
      desc += "Request Canceled";
      break;
  }
  return desc;
}

export function convertObjectToForm(object) {
  var uploadData: any = new FormData();
  for (const [key, value] of (<any>Object).entries(object)) {
    if (key == "newBirthday" && value != null) {
      uploadData.append(key + "_year", value.year);
      uploadData.append(key + "_month", value.month);
      uploadData.append(key + "_day", value.day);
    } else {
      if (value != null) {
        uploadData.append(key, value);
      }
    }
  }
  return uploadData;
}

export function moveObjectFromArrayToAnother(object, oldList, newList) {
  var oldListIndex = findIndexById(object, oldList);
  //Remove From Old list
  if (oldListIndex > -1) {
    oldList.splice(oldListIndex, 1);
  }
  //Add To New list
  newList.push(object);
}

export function findIndexById(object, array) {
  for (var i = 0; i < array.length; i++) {
    if (object.id == array[i].id) {
      return i;
    }
  }
  return -1;
}
export function getIdsOfArray(array) {
  var ids = [];
  for (var i = 0; i < array.length; i++) {
    ids.push(array[i].id);
  }
  return ids;
}
export function groupPermissions(
  currentModule,
  permissions,
  defaultValueFlag,
  currentValues
) {
  if (currentModule == "rez") {
    return groupPermissionsForRez(permissions, defaultValueFlag, currentValues);
  }
  if (currentModule == "pass") {
    return groupPermissionsForPass(
      permissions,
      defaultValueFlag,
      currentValues
    );
  }
  if (currentModule == "wedding") {
    return groupPermissionsForRez(permissions, defaultValueFlag, currentValues);
  }

}
export function groupPermissionsForRez(
  permissions,
  defaultValueFlag,
  currentValues
) {
  var usersManagement = [];
  var systemsetup = [];
  var reservationsAndCustomers = [];
  var reservations = [];
  var customers = [];
  var finance = [];
  var others = [];
  var currentValueForReservationsAndCustomersGroup =
    "permission_reservationcustomersmanage";
  var reservationsManagePermissionFound = false;
  var reservationsViewPermissionFound = false;

  for (var i = 0; i < permissions.length; i++) {
    var title = permissions[i].name;
    permissions[i].disabled = false;

    if (defaultValueFlag) {
      permissions[i].checked = true;
      switch (title) {
        case "permission_reservationcustomersview":
        case "permission_usermanagement_rootaccess":
        case "permission_gatechecker":
          permissions[i].checked = false;
          break;
      }
    } else {
      var permissionIsChecked = isPermissionIsChecked(
        currentValues,
        permissions[i]
      );
      if (
        permissions[i].name == "permission_reservationcustomersview" &&
        permissionIsChecked
      ) {
        reservationsViewPermissionFound = true;
      } else if (
        permissions[i].name == "permission_reservationcustomersmanage" &&
        permissionIsChecked
      ) {
        reservationsManagePermissionFound = true;
      } else {
        permissions[i].checked = permissionIsChecked;
      }
    }

    switch (title) {
      case "permission_reservationcustomersview":
      case "permission_reservationcustomersmanage":
        reservationsAndCustomers.push(permissions[i]);
        break;
      case "permission_usermanagement":
      case "permission_usermanagement_rootaccess":
      case "permission_usermanagement_roles":
        usersManagement.push(permissions[i]);
        break;
      case "permission_tablesetup":
      case "permission_setup_mcompany":
      case "permission_configs":
        systemsetup.push(permissions[i]);
        break;
      case "permission_reservationscustomers_create":
      case "permission_reservations_edit":
      case "permission_reservations_confirm":
      case "permission_reservations_close":
      case "permission_reservations_checkin":
      case "permission_reservations_cancel":
      case "permission_reservations_managedeposit":
      case "permission_reservations_allocatetables":
      case "permission_reservations_walkins":
      case "permission_reservations_handlerequests":
        reservations.push(permissions[i]);
        break;
      case "permission_customersdetails":
      case "permission_customers_changecc":
      case "permission_customers_blacklist":
      case "permission_customers_rejectlist":
      case "permission_customers_changemobileno":
      case "permission_customers_changedetails":
      case "permission_customers_delete":
        customers.push(permissions[i]);
        break;
      case "permission_reports":
      case "permission_paymenthandle":
      case "permission_customerfinance":
        finance.push(permissions[i]);
        break;
      case "permission_gatechecker":
        others.push(permissions[i]);
        break;
    }
  }

  //Add None Option For Reservations & customers
  reservationsAndCustomers.push({
    id: -1,
    name: "permission_reservationcustomers_none",
    alias: "Can not view reservations or Customers",
    disabled: false,
    checked: false
  });

  //Check Reservations Group & Customers default radio value
  if (!defaultValueFlag) {
    if (
      !reservationsViewPermissionFound &&
      !reservationsManagePermissionFound
    ) {
      currentValueForReservationsAndCustomersGroup =
        "permission_reservationcustomers_none";
    } else if (reservationsManagePermissionFound) {
      currentValueForReservationsAndCustomersGroup =
        "permission_reservationcustomersmanage";
    } else {
      currentValueForReservationsAndCustomersGroup =
        "permission_reservationcustomersview";
    }
  }

  var main = [
    {
      alias: "reservations_customers",
      name: "Reservations & Customers",
      permissions: reservationsAndCustomers,
      radio: true,
      groupid: "reservationsncustomers",
      groupvalue: currentValueForReservationsAndCustomersGroup
    },
    {
      alias: "reservations",
      name: "Reservations Management",
      permissions: reservations,
      radio: false
    },
    {
      alias: "customers",
      name: "Customers Management",
      permissions: customers,
      radio: false
    },
    {
      alias: "users_management",
      name: "Users Management",
      permissions: usersManagement,
      radio: false
    },
    {
      alias: "systemsetup",
      name: "System Setup",
      permissions: systemsetup,
      radio: false
    },
    {
      alias: "finance",
      name: "Finance",
      permissions: finance,
      radio: false
    },
    {
      alias: "others",
      name: "Others",
      permissions: others,
      radio: false
    },
  ];
  return main;
}

export function groupPermissionsForPass(
  permissions,
  defaultValueFlag,
  currentValues
) {
  var usersManagement = [];
  var systemsetup = [];
  var reservationsAndCustomers = [];
  var reservations = [];
  var customers = [];
  var events = [];
  var finance = [];
  var currentValueForReservationsAndCustomersGroup =
    "permission_ticketscustomers_manage";
  var reservationsManagePermissionFound = false;
  var reservationsViewPermissionFound = false;

  for (var i = 0; i < permissions.length; i++) {
    var title = permissions[i].name;
    permissions[i].disabled = false;

    if (defaultValueFlag) {
      permissions[i].checked = true;
      switch (title) {
        case "permission_ticketscustomers_viewonly":
        case "permission_usermanagement_rootaccess":
          permissions[i].checked = false;
          break;
      }
    } else {
      var permissionIsChecked = isPermissionIsChecked(
        currentValues,
        permissions[i]
      );
      if (
        permissions[i].name == "permission_ticketscustomers_viewonly" &&
        permissionIsChecked
      ) {
        reservationsViewPermissionFound = true;
      } else if (
        permissions[i].name == "permission_ticketscustomers_manage" &&
        permissionIsChecked
      ) {
        reservationsManagePermissionFound = true;
      } else {
        permissions[i].checked = permissionIsChecked;
      }
    }

    switch (title) {
      case "permission_ticketscustomers_manage":
      case "permission_ticketscustomers_viewonly":
        reservationsAndCustomers.push(permissions[i]);
        break;
      case "permission_usermanagement":
      case "permission_usermanagement_rootaccess":
      case "permission_usermanagement_roles":
        usersManagement.push(permissions[i]);
        break;
      case "permission_tablesetup":
      case "permission_setup_mcompany":
      case "permission_configs":
        systemsetup.push(permissions[i]);
        break;
      case "permission_ticketorder_create":
      case "permission_ticketorder_edit":
      case "permission_ticketorder_approve":
      case "permission_ticketorder_reject":
      case "permission_ticketorder_cancel":
      case "permission_ticketorder_cancelallunpaidtickets":
      case "permission_ticketorder_allocatetables":
      case "permission_ticketorder_checkin":
      case "permission_ticketorder_removeticket":
      case "permission_ticketorder_markaspaid":
      case "permission_ticketorder_refundpayment":
      case "permission_ticketorder_managepaymentlink":
      case "permission_ticketorder_seperatemergepayments":
      case "permission_ticketorder_tags":
      case "permission_ticketorder_sendnotificationsms":
        reservations.push(permissions[i]);
        break;
      case "permission_customersdetails":
      case "permission_customers_changecc":
      case "permission_customers_blacklist":
      case "permission_customers_rejectlist":
      case "permission_customers_changemobileno":
      case "permission_customers_changedetails":
      case "permission_customers_delete":
        customers.push(permissions[i]);
        break;
      case "permission_pass_reports":
      case "permission_paymenthandle":
      case "permission_customerfinance":
        finance.push(permissions[i]);
        break;
      case "permission_events_access":
      case "permission_events_manage":
      case "permission_events_sections":
      case "permission_events_tickettypes":
      case "permission_events_ticketcodes":
      case "permission_events_notifications":
      case "permission_events_autonotifications":
      case "permission_events_publish":
      case "permission_events_operations":
        events.push(permissions[i]);
        break;
    }
  }

  //Add None Option For Reservations & customers
  reservationsAndCustomers.push({
    id: -1,
    name: "permission_ticketscustomers_none",
    alias: "Can not view ticket orders or Customers",
    disabled: false,
    checked: false
  });

  //Check Reservations Group & Customers default radio value
  if (!defaultValueFlag) {
    if (
      !reservationsViewPermissionFound &&
      !reservationsManagePermissionFound
    ) {
      currentValueForReservationsAndCustomersGroup =
        "permission_ticketscustomers_none";
    } else if (reservationsManagePermissionFound) {
      currentValueForReservationsAndCustomersGroup =
        "permission_ticketscustomers_manage";
    } else {
      currentValueForReservationsAndCustomersGroup =
        "permission_ticketscustomers_viewonly";
    }
  }

  var main = [
    {
      alias: "events",
      name: "Events",
      permissions: events,
      radio: false
    },
    {
      alias: "reservations_customers",
      name: "Ticket Orders & Customers",
      permissions: reservationsAndCustomers,
      radio: true,
      groupid: "reservationsncustomers",
      groupvalue: currentValueForReservationsAndCustomersGroup
    },
    {
      alias: "reservations",
      name: "Ticket Orders Management",
      permissions: reservations,
      radio: false
    },
    {
      alias: "customers",
      name: "Customers Management",
      permissions: customers,
      radio: false
    },
    {
      alias: "users_management",
      name: "Users Management",
      permissions: usersManagement,
      radio: false
    },
    {
      alias: "systemsetup",
      name: "System Setup",
      permissions: systemsetup,
      radio: false
    },
    {
      alias: "finance",
      name: "Finance",
      permissions: finance,
      radio: false
    }
  ];
  return main;
}

export function collectSelectedPermissions(permissionList) {
  var permissions = [];
  var ids = [];
  for (var i = 0; i < permissionList.length; i++) {
    if (permissionList[i].groupid == "reservationsncustomers") {
      if (
        permissionList[i].groupvalue !=
        "permission_reservationcustomers_none" &&
        permissionList[i].groupvalue != "permission_ticketscustomers_none"
      ) {
        permissions.push(permissionList[i].groupvalue);
        ids.push(
          getPermissionIdByNameInsideGroup(
            permissionList[i].permissions,
            permissionList[i].groupvalue
          )
        );
      }
      continue;
    }

    for (var j = 0; j < permissionList[i].permissions.length; j++) {
      if (permissionList[i].permissions[j].id == -1) {
        continue;
      }
      if (permissionList[i].permissions[j].checked) {
        permissions.push(permissionList[i].permissions[j].name);
        ids.push(permissionList[i].permissions[j].id);
      }
    }
  }
  return { ids: ids, permissions: permissions };
}

export function getPermissionIdByNameInsideGroup(permissions, name) {
  for (var i = 0; i < permissions.length; i++) {
    if (permissions[i].name == name) {
      return permissions[i].id;
    }
  }
  return -1;
}

export function getGroupOfPermissionByAlias(permissionList, alias) {
  for (var i = 0; i < permissionList.length; i++) {
    if (permissionList[i].alias == alias) {
      return permissionList[i].permissions;
    }
  }
  return [];
}
export function getGroupByAlias(permissionList, alias) {
  for (var i = 0; i < permissionList.length; i++) {
    if (permissionList[i].alias == alias) {
      return permissionList[i];
    }
  }
  return null;
}

export function isBirthdayFlag(customerProfile, date) {
  if (customerProfile == null) {
    return false;
  }
  if (customerProfile.birthdate == null) {
    return false;
  }
  if (customerProfile.birthdate == "") {
    return false;
  }
  var birthDate = formatDateSqlToCustomObject(customerProfile.birthdate);
  var todayDate = formatDateSqlToCustomObject(date);
  return compareCustomDateForBirthday(birthDate, todayDate);
}

function isPermissionIsChecked(currentValues, permission) {
  for (var i = 0; i < currentValues.length; i++) {
    if (currentValues[i].id == permission.id) {
      return true;
    }
  }
  return false;
}

export function filterReservedTablesFromReservations(
  reservations,
  reservedTables,
  timeFrom,
  timeTo
) {
  var idsOfReservationsArrayWithinCriteria = [];
  for (var i = 0; i < reservations.length; i++) {
    var isWithinTimeRange = isReservationWithinTimeRange(
      reservations[i],
      timeFrom,
      timeTo
    );
    if (isWithinTimeRange) {
      idsOfReservationsArrayWithinCriteria.push(reservations[i].id);
    }
  }
  var filteredReservedTables = [];
  for (var i = 0; i < reservedTables.length; i++) {
    if (
      idsOfReservationsArrayWithinCriteria.indexOf(
        reservedTables[i].reservation_id
      ) !== -1
    ) {
      filteredReservedTables.push(reservedTables[i]);
    }
  }
  return {
    filteredReservationsId: idsOfReservationsArrayWithinCriteria,
    filteredReservedTables: filteredReservedTables
  };
}
export function isDateWithinDateRange(
  dateToCompare,
  dateFrom,
  dateTo
) {
  var parsedDate = formatDateSqlToDateObject(dateToCompare);
  var dateFromInDateObject = formatDateCustomObjectToDate(dateFrom);
  var dateToInDateObject = formatDateCustomObjectToDate(dateTo);

  return parsedDate >= dateFromInDateObject && parsedDate <= dateToInDateObject;
}
export function isReservationWithinTimeRange(
  reservation,
  timeFromDateObject,
  timeToDateObject
) {
  var reservationFromDate = formatDateTimeSqlToDateObject(
    reservation.from_time
  );
  var reservationToDate = formatDateTimeSqlToDateObject(reservation.to_time);
  //var timeFromDateObject = formatDateTimeSqlToDateObject(timeFrom);
  //var timeToDateObject = formatDateTimeSqlToDateObject(timeTo);
  if (
    (reservationFromDate.getTime() >= timeFromDateObject.getTime() &&
      reservationFromDate.getTime() < timeToDateObject.getTime()) ||
    (reservationToDate.getTime() > timeFromDateObject.getTime() &&
      reservationToDate.getTime() <= timeToDateObject.getTime()) ||
    (reservationFromDate.getTime() < timeFromDateObject.getTime() &&
      reservationToDate.getTime() >= timeToDateObject.getTime())
  ) {
    return true;
  }
  return false;
}
export function formatDateObjectToTime12Format(date, datePipe) {
  return datePipe.transform(date, "hh:mm aaa");
}
export function detectTimeRangeBasedOnNow(
  date,
  timeFrom,
  timeTo,
  rangeInHours,
  datePipe
) {
  var now = new Date();
  var detectedStartTime = null;
  var detectedEndTime = null;
  var startFromDate = formatDateTimeSqlToDateObject(
    formatDateCustomObjectToSql(date) + " " + timeFrom
  );
  var endFromDate = formatDateTimeSqlToDateObject(
    formatDateCustomObjectToSql(date) + " " + timeTo
  );
  if (startFromDate < now) {
    detectedStartTime = roundTimeDownToNearestValue(now, 30);
    if (detectedStartTime < startFromDate) {
      detectedStartTime = startFromDate;
    }
  } else {
    detectedStartTime = startFromDate;
  }
  detectedEndTime = addHoursToDate(detectedStartTime, rangeInHours);
  if (detectedEndTime < endFromDate) {
    detectedEndTime = endFromDate;
  }

  var fromTimeString = formatDateObjectToTime12Format(
    detectedStartTime,
    datePipe
  );
  var toTimeString = formatDateObjectToTime12Format(detectedEndTime, datePipe);
  return {
    from: {
      formatted: fromTimeString,
      date: detectedStartTime
    },
    to: {
      formatted: toTimeString,
      date: detectedEndTime
    }
  };
}

export function addMinutesToDate(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}
export function addHoursToDate(date, hours) {
  return new Date(date.getTime() + hours * 60 * 60000);
}
export function roundTimeDownToNearestValue(date, minutes) {
  var coff = 1000 * 60 * minutes;
  return new Date(Math.floor(date / coff) * coff);
}
export function roundTimeUpToNearestValue(date, minutes) {
  var coff = 1000 * 60 * minutes;
  return new Date(Math.ceil(date / coff) * coff);
}
export function numberOfMinutesBetweenDates(date1, date2) {
  var diff = Math.abs(date1 - date2);
  return Math.floor(diff / 1000 / 60);
}
export function dateIsWithinTwoDates(date, date1, date2) {
  return date.getTime() > date1.getTime() && date.getTime() < date2.getTime();
}
export function createQRCodeFromTicketParams(params) {
  var value = "";
  var delimitter = "|";
  value += params.code + delimitter;
  value += params.reference + delimitter;
  value += params.customerMobile + delimitter;
  value += params.customerName + delimitter;
  value += params.eventName + delimitter;
  value += params.id + delimitter;
  value += params.ticketName + delimitter; //+"@";
  value += "efoh";
  //value+= params.signature;
  return value;
}
export function createTicketParamsFromQRCode(qrCodeString) {
  var delimitter = "|";
  var secondSplitArray = qrCodeString.split(delimitter);
  if (secondSplitArray.length != 8) {
    return false;
  }
  if (secondSplitArray[7] != "efoh") {
    return false;
  }
  return {
    code: secondSplitArray[0],
    reference: secondSplitArray[1],
    customerMobile: secondSplitArray[2],
    customerName: secondSplitArray[3],
    eventName: secondSplitArray[4],
    customerId: secondSplitArray[5],
    ticketName: secondSplitArray[6]
  };
}

export function getGeoCordsFromString(string) {
  var arrayOfCords = string.split(",");
  return { lat: parseFloat(arrayOfCords[0]), lng: parseFloat(arrayOfCords[1]) };
}
export function getGeoCordsCircleFromString(string) {
  var arrayOfCords = string.split(",");
  return {
    lat: parseFloat(arrayOfCords[0]),
    lng: parseFloat(arrayOfCords[1]),
    radius: parseFloat(arrayOfCords[2])
  };
}
export function getStringFromGeoCords(geoCords) {
  return geoCords["lat"] + "," + geoCords["lng"];
}
export function getStringFromGeoCordsCircle(geoCords) {
  return geoCords["lat"] + "," + geoCords["lng"] + "," + geoCords["radius"];
}
export function getCurrentBaseUrl(routerService) {
  var currentAbsoluteUrl = window.location.href;
  var currentRelativeUrl = routerService.url;
  var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
  var baseUrl = currentAbsoluteUrl.substring(0, index);
  return baseUrl;
}

export function getTicketStatusMetadata(statusInString) {
  const status = parseInt(statusInString);

  let statusText = "";
  let statusCss = "";
  switch (status) {
    case 0:
      statusText = "Payment Failed";
      statusCss = "color-pass-canceled";
      break;
    case 1:
      statusText = "Confirmed";
      statusCss = "color-pass-paid";
      break;
    case 2:
      statusText = "Pending Approval";
      statusCss = "color-pass-papproval";
      break;
    case 3:
      statusText = "Canceled";
      statusCss = "color-pass-canceled";
      break;
    case 4:
      statusText = "Expired";
      statusCss = "color-pass-canceled";
      break;
    case 5:
      statusText = "Pending Payment";
      statusCss = "color-pass-ppayment";
      break;
    case 6:
      statusText = "Rejected";
      statusCss = "color-pass-canceled";
      break;
    case 7:
      statusText = "Refunded";
      statusCss = "color-pass-refunded";
      break;
    case 9:
      statusText = "Pending Confirmation";
      statusCss = "color-pass-pconfirmation";
      break;
    case 10:
      statusText = "To be Invited";
      statusCss = "color-pass-tobeinvited";
      break;
    case 11:
      statusText = "Pending RSVP";
      statusCss = "color-pass-pendingrsvp";
      break;
    case 12:
      statusText = "RSVP";
      statusCss = "color-pass-rsvp";
      break;
    case 13:
      statusText = "Not Attending";
      statusCss = "color-pass-notattending";
      break;
    default:
      statusText = "Unknown";
      statusCss = "color-pass-default";
  }
  return { text: statusText, style: statusCss };
}

export function collectTicketOrderIdsFromTicketIds(ticketIds, guestlist) {
  var ticketOrderIds = [];
  for (var i = 0; i < guestlist.length; i++) {
    if (!ticketIds.includes(guestlist[i].id)) {
      continue;
    }
    ticketOrderIds.push(guestlist[i].ticketorder_id);
    if (
      guestlist[i].attached_addons_services == null ||
      guestlist[i].attached_addons_services == ""
    ) {
      continue;
    }
    var arrayOfAddons = guestlist[i].attached_addons_services.split(";");
    for (var j = 0; j < arrayOfAddons.length; j++) {
      ticketOrderIds.push(arrayOfAddons[j]);
    }
  }
  return ticketOrderIds;
}
export function managePermissionsForEvents(authService, globals) {
  let allowedActions: any = {};
  allowedActions.canManageEvents = authService.havePermission(
    globals.PERMISSION_EVENTS_MANAGEDETAILS
  );
  allowedActions.canManageSections = authService.havePermission(
    globals.PERMISSION_EVENTS_SECTIONS
  );
  allowedActions.canManageTicketTypes = authService.havePermission(
    globals.PERMISSION_EVENTS_TICKETTYPES
  );
  allowedActions.canManageTicketCodes = authService.havePermission(
    globals.PERMISSION_EVENTS_TICKETCODES
  );
  allowedActions.canManageNotifications = authService.havePermission(
    globals.PERMISSION_EVENTS_NOTIFICATIONS
  );
  allowedActions.canManageAutoAlerts = authService.havePermission(
    globals.PERMISSION_EVENTS_AUTOALERTS
  );
  allowedActions.canPublishEvents = authService.havePermission(
    globals.PERMISSION_EVENTS_PUBLISH
  );
  allowedActions.canManageOperations = authService.havePermission(
    globals.PERMISSION_EVENTS_OPERATIONS
  );
  return allowedActions;
}

export function managePermissionsForReservations(authService, globals) {
  let allowedActions: any = {};
  allowedActions.walkinOnlyMode = false;
  allowedActions.seperateMergeTickets = false;
  allowedActions.managePayments = false;
  allowedActions.refundPayment = false;
  allowedActions.markAsPaidCash = false;
  allowedActions.removeTicket = false;
  allowedActions.ticketManagement = false;
  allowedActions.canRejectTicketRequest = false;
  allowedActions.canApproveTicketRequest = false;
  allowedActions.canBulkCancelUnPaidTickets = false;
  allowedActions.canSendNotifications = true;

  allowedActions.addReservation = authService.havePermission(
    globals.PERMISSION_RESERVATIONSCUSTOMERS_CREATE
  );
  allowedActions.addWalkin = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_WALKINS
  );
  if (allowedActions.addWalkin && !allowedActions.addReservation) {
    allowedActions.walkinOnlyMode = true;
  }
  allowedActions.editReservation = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_EDIT
  );
  allowedActions.confirmReservation = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_CONFIRM
  );
  allowedActions.checkInGuests = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_CHECKIN
  );
  allowedActions.cancelReservation = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_CANCEL
  );
  allowedActions.closeReservation = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_CLOSE
  );
  allowedActions.depositManagement = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_MANAGEDEPOSIT
  );
  allowedActions.guestlistManagement = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_EDIT
  );
  allowedActions.ticketManagement = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_EDIT
  );
  allowedActions.allocation = authService.havePermission(
    globals.PERMISSION_RESERVATIONS_ALLOCATETABLES
  );
  return allowedActions;
}
export function managePermissionsForPass(authService, globals) {
  let allowedActions: any = {};
  allowedActions.walkinOnlyMode = false;
  allowedActions.guestlistManagement = false;
  allowedActions.depositManagement = false;
  allowedActions.closeReservation = false;
  allowedActions.addWalkin = false;

  allowedActions.addReservation = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CREATE
  );
  allowedActions.editReservation = authService.havePermission(
    globals.PERMISSION_TICKETORDER_EDIT
  );
  allowedActions.checkInGuests = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CHECKIN
  );
  allowedActions.cancelReservation = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CANCEL
  );
  allowedActions.allocation = authService.havePermission(
    globals.PERMISSION_TICKETORDER_ALLOCATETABLES
  );
  allowedActions.ticketManagement = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CREATE
  );
  allowedActions.removeTicket = authService.havePermission(
    globals.PERMISSION_TICKETORDER_REMOVETICKET
  );
  allowedActions.markAsPaidCash = authService.havePermission(
    globals.PERMISSION_TICKETORDER_REMOVETICKET
  );
  allowedActions.refundPayment = authService.havePermission(
    globals.PERMISSION_TICKETORDER_REFUNDPAYMENT
  );
  allowedActions.managePayments = authService.havePermission(
    globals.PERMISSION_TICKETORDER_MANAGEPAYMENTLINK
  );
  allowedActions.seperateMergeTickets = authService.havePermission(
    globals.PERMISSION_TICKETORDER_SEPERATEMERGEPAYMENTGROUP
  );
  allowedActions.canSendNotifications = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CANSENDNOTIFICATIONSMS
  );
  allowedActions.canBulkCancelUnPaidTickets = authService.havePermission(
    globals.PERMISSION_TICKETORDER_CANCELALLUNPAIDTICKETS
  );
  allowedActions.canApproveTicketRequest = authService.havePermission(
    globals.PERMISSION_TICKETORDER_APPROVE
  );
  allowedActions.canRejectTicketRequest = authService.havePermission(
    globals.PERMISSION_TICKETORDER_REJECT
  );
  return allowedActions;
}

export function getAvailableOverBookedTickets(value) {
  var valueInteger = parseInt(value);
  if (valueInteger < 0) {
    return { current: 0, overbooked: Math.abs(valueInteger) };
  }
  return {
    current: valueInteger,
    overbooked: 0
  };
}
export function getAllowedNumberOfTickets(tempTicket) {
  //Create Here Logic based on Event Config.
  //First Come, First Serve
  return tempTicket.maximumallowed;
  /*Get Allowed Number of tickets became server side for security purpose*/
  // var availableTickets  = parseInt(tempTicket.quantity) - parseInt(tempTicket.total_paid);
  // var allowedTickets = parseInt(tempTicket.max);
  // if (allowedTickets > availableTickets) {
  //   allowedTickets = availableTickets;
  // }
  // if (allowedTickets < tempTicket.min) {
  //   allowedTickets = 0;
  // }
  // return allowedTickets;
}
export function getMysqlDatesBetweenTwoDatesCustom(fromDate, toDate) {
  var startDate = formatDateCustomObjectToDate(fromDate);
  var endDate = formatDateCustomObjectToDate(toDate);
  const datesArray = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1 < 10)
      ? '0' + (currentDate.getMonth() + 1)
      : currentDate.getMonth() + 1;
    const day = (currentDate.getDate() < 10)
      ? '0' + currentDate.getDate()
      : currentDate.getDate();
    const formattedDate = `${year}-${month}-${day}`;
    datesArray.push(formattedDate);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesArray;
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FeedbackService } from './service/feedback.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm!: any;
  answers: any[] = [];
  referenceCode: string = '';
  formStyle: any = {};
  en: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private feedbackService: FeedbackService
  ) { }

  ngOnInit() {

    this.feedbackForm = {
      "id": 1,
      "mcompany_id": 24,
      "name": "Customer Satisfaction Survey",
      "name_ar": "استطلاع رضا العملاء",
      "description": "A survey to gather customer feedback",
      "description_ar": "استطلاع لجمع تعليقات العملاء",
      "active": true,
      "public": false,
      "anonymous": false,
      "background_color": "#FFFFFF",
      "primary_color": "#007BFF",
      "secondary_color": "#6C757D",
      "accent_color": "#28A745",
      "logo": "logo_1742466795_OJ1j0g1AXl.jpg",
      "alias": "customer-survey",
      "created_at": "2025-03-17T13:56:07.000000Z",
      "updated_at": "2025-03-20T10:33:16.000000Z",
      "questions": [
          {
              "id": 6,
              "feed_back_form_id": 1,
              "question": "Please provide any additional comments",
              "question_ar": "يرجى تقديم أي تعليقات إضافية",
              "type": "text",
              "options": null,
              "options_ar": null,
              "required": false,
              "order": 4,
              "created_at": "2025-03-17T14:14:38.000000Z",
              "updated_at": "2025-03-17T14:14:38.000000Z"
          },
          {
              "id": 7,
              "feed_back_form_id": 1,
              "question": "Please rate our service from 1 to 5",
              "question_ar": "يرجى تقييم خدمتنا من 1 إلى 5",
              "type": "integer",
              "options": null,
              "options_ar": null,
              "required": true,
              "order": 2,
              "created_at": "2025-03-17T14:14:46.000000Z",
              "updated_at": "2025-03-17T14:14:46.000000Z"
          }
      ]
  };

  this.formStyle = {
    'background-color': this.feedbackForm.background_color,
    'color': this.feedbackForm.primary_color,
    '--primary-color': this.feedbackForm.primary_color,
    '--secondary-color': this.feedbackForm.secondary_color
  };

  this.answers = this.feedbackForm.questions.map((question: any) => ({
    question_id: question.id,
    value: null
  }));


    this.referenceCode = this.route.snapshot.queryParams['38zbnctshmfvw8u'];
    // this.getFullMenu(this.referenceCode);
  }

  getQuestionOptions(options: string) {
    try {
      return JSON.parse(options);
    } catch {
      return [];
    }
  }

  answersValid() {
    return this.feedbackForm.questions.every((q: any, i: number) => {
      if (!q.required) return true;
      return this.answers[i].value !== null && this.answers[i].value !== '';
    });
  }

  getFullMenu(reference_code: any) {
    let params = new HttpParams();
    params = params.set("reference_code", reference_code);
    this.feedbackService.getFullMenu(params).subscribe({
      next: (payload) => {
        this.feedbackForm = payload;


        this.formStyle = {
          'background-color': this.feedbackForm.background_color,
          'color': this.feedbackForm.primary_color,
          '--primary-color': this.feedbackForm.primary_color,
          '--secondary-color': this.feedbackForm.secondary_color
        };
    
        this.answers = this.feedbackForm.questions.map((question: any) => ({
          question_id: question.id,
          value: null
        }));

      }
    });
  }

  submitForm() {
    console.log(this.answers);
    const formData = new FormData();



    this.answers.forEach((answer, index) => {
      formData.append(`answers[${index}][question_id]`, answer.question_id.toString());
      if (answer.value !== null) {
        formData.append(`answers[${index}][value]`, answer.value.toString());
      }
    });

    formData.append('reference_code', this.referenceCode);

    this.feedbackService.postAnswers(formData).subscribe({
      next: (payload) => {
        console.log(payload);
      }
    });

    // formData.forEach((val, key) => {
    //   console.log(val, key);
    // });
  }
  toggleLanguage() {
    this.en = !this.en;
    document.documentElement.setAttribute('dir', this.en ? 'ltr' : 'rtl');
    // Optionally set the lang attribute for accessibility
    document.documentElement.setAttribute('lang', this.en ? 'en' : 'ar');
  }

  // Added helper method to get form name based on current language
  getFormName(): string {
    return this.en ? this.feedbackForm.name : this.feedbackForm.name_ar;
  }

  // Added helper method to get form description based on current language
  getFormDescription(): string {
    return this.en ? this.feedbackForm.description : this.feedbackForm.description_ar;
  }

  // Added helper method to get question text based on current language
  getQuestionText(question: any): string {
    return this.en ? question.question : question.question_ar;
  }
  getQuestionOptionsTranslated(question: any): any[] {
    try {
      const options = this.en ? question.options : question.options_ar; 
      return options ? JSON.parse(options) : [];
    } catch {
      return [];
    }
  }
}
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Globals } from "@foh/globals";
import { filter, map, mergeMap } from "rxjs/operators";
declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {

  constructor(public router: Router, private globals: Globals, private activatedRoute: ActivatedRoute,
    private titleService: Title) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag("config", this.globals.GOOGLEANALYTICS, {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(event => {
      this.titleService.setTitle(event['title'] || 'Default Title');
    });
  }
}

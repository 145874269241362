import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

@Component({
  selector: "app-module-switcher",
  templateUrl: "./module-switcher.component.html",
  styleUrls: ["./module-switcher.component.scss"]
})
export class ModuleSwitcherComponent implements OnInit {
  constructor(
    public activeRoute: ActivatedRoute,
    private fohmoduleSelectorService: FohmoduleSelectorService
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      var newModule = params["module"];
      this.fohmoduleSelectorService.changeModule(newModule);
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"]
})
export class LandingPageComponent implements OnInit {
  currentSelectedModule: string;
  constructor(
    private fohmoduleSelectorService: FohmoduleSelectorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();
    switch (this.currentSelectedModule) {
      case "rez":
        this.router.navigate(["rez/dashboard"]);
        break;
      case "pass":
        this.router.navigate(["pass/events/list"]);
        break;
      case "ordering":
        this.router.navigate(["ordering/dashboard"]);
        break;
      case "queue":
        this.router.navigate(["customers/list"]);
        break;
      case "wedding":
        this.router.navigate(["customers/list"]);
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { LookupService } from '../services/lookup.service';
import { Observable } from 'rxjs';
import { Resolve } from '@angular/router';

@Injectable()
export class LookupResolver implements Resolve<any> {
  constructor(private lookupService: LookupService) {}

  resolve() {
       return this.lookupService.getLookups();
  }
}
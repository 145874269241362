import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { ModuleSwitcherComponent } from './module-switcher/module-switcher.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthGuard } from './shared/auth/auth-guard.service';
import { LookupResolver } from './shared/resolvers/lookup.resolver';
import { SocketResolver } from './shared/resolvers/socket.resolver';

const appRoutes: Routes = [
  { path: '', redirectTo: 'landingpage', pathMatch: 'full', },
  { path: 'landingpage', component: LandingPageComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard], resolve: { items: LookupResolver, SocketResolver } },
  { path: 'module/switchto/:module', component: ModuleSwitcherComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard], resolve: { items: LookupResolver, SocketResolver } },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard], resolve: { items: LookupResolver, SocketResolver } },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
];

export const routingConfiguration: ExtraOptions = {
  relativeLinkResolution: 'legacy',
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, routingConfiguration)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
